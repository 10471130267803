/*global angular, $*/
'use strict';

/** @type {string} */
var BASE_TEMPLATE_TAG = 'ベーステンプレート';

module.exports = {
    templateUrl: './js/shared-video-list/shared-video-list.template.html',
    controller: [
        '$rootScope', '$scope', '$state', '$stateParams', '$translate', '$uibModal', 'RestHttp', 'Notification',
        function ($rootScope, $scope, $state, $stateParams, $translate, $uibModal, RestHttp, Notification) {
            /** @type{SharedVideoListComponent} */
            var self = this;

            /**
             * @param {string} key
             */
            function translate(key) {
                //noinspection JSCheckFunctionSignatures
                return $translate.instant(key);
            }

            function populateSizes() {
                /** @type {number} */
                var i;
                /** @type {VideoDescription} */
                var videoDescription;
                /** @type {{width: number, height: number}} */
                var size;
                /** @type {string} */
                var sizeName;
                /** @type {Object.<boolean>} */
                var addedSizes = {};

                for (i = 0; i < self.data.length; i += 1) {
                    videoDescription = self.data[i];

                    if (self.mode === 1) {
                        size = videoDescription.templateData.size;
                    } else {
                        size = videoDescription.description.size;
                    }

                    sizeName = size.width + 'x' + size.height;

                    if (!addedSizes[sizeName]) {
                        addedSizes[sizeName] = size;
                    }
                }

                const availableSizes = Object.getOwnPropertyNames(addedSizes).map(sizeName => addedSizes[sizeName]);
                availableSizes.sort((size, otherSize) => {
                    if (size.width === otherSize.width) {
                        return size.height - otherSize.height;
                    }

                    return size.width - otherSize.width;
                });

                availableSizes.forEach(size => self.availableSizes.push({
                    name: size.width + 'x' + size.height,
                    value: size
                }));
            }

            function populateTags() {
                /** @type {number} */
                var i, j;
                /** @type {VideoDescription} */
                var videoDescription;
                /** @type {Array.<string>} */
                var tags;
                /** @type {string} */
                var tag;
                /** @type {Object.<boolean>} */
                var addedTags = {};

                for (i = 0; i < self.data.length; i += 1) {
                    videoDescription = self.data[i];

                    if (self.mode === 1) {
                        tags = videoDescription.templateData.tags;
                    } else {
                        tags = videoDescription.description.tags;
                    }

                    for (j = 0; j < tags.length; j += 1) {
                        tag = tags[j];

                        if (!addedTags[tag]) {
                            addedTags[tag] = true;

                            self.availableTags.push({
                                name: tag,
                                value: tag
                            });
                        }
                    }
                }
            }

            function setVideoPosters() {
                /** @type {number} */
                var i;

                for (i = 0; i < self.data.length; i += 1) {
                    $('#video-' + i).get(0).poster = self.getThumbnailAsDataURL(i);
                }
            }

            /**
             * @param {string} stateName
             * @returns {number}
             */
            function getMode(stateName) {
                if (stateName === 'root.list.templates') {
                    return 1;
                }
                if (stateName === 'root.list.recent') {
                    return 2;
                }
                if (stateName === 'root.list.videos') {
                    return 3;
                }

                console.error('Unknown mode for state ' + stateName);
                return -1;
            }

            /**
             * @param {function(Error=)} callback
             */
            function getTemplates(callback) {
                RestHttp.restGet('listTemplates').then(function (data) {
                    self.data = data;
                    self.data.forEach(function (template) {
                        template.isBaseTemplate = template.templateData.tags.indexOf(BASE_TEMPLATE_TAG) !== -1;
                    });
                    callback();
                }, callback);
            }

            /**
             * @param {function(Error=)} callback
             */
            function getVideos(callback) {
                var restMethod;

                if (self.mode === 3) {
                    restMethod = RestHttp.restPost.bind(RestHttp, 'listUserVideos', {username: self.username});
                } else {
                    restMethod = RestHttp.restGet.bind(RestHttp, 'listVideos');
                }

                restMethod().then(function (data) {
                    self.data = data;
                    callback();
                }, callback);
            }

            /**
             * @param {function(Error=)} callback
             */
            function getData(callback) {
                if (self.mode === 1) {
                    self.username = $rootScope.username;
                    getTemplates(callback);
                } else if (self.mode === 2) {
                    self.username = $rootScope.username;
                    getVideos(callback);
                } else if (self.mode === 3) {
                    self.username = $stateParams.username;
                    getVideos(callback);
                }
            }

            self.reload = function () {
                self.mode = getMode($state.current.name);

                // filters init
                self.availableSizes = [{
                    name: '全部',
                    value: 'all'
                }];
                self.selectedSize = self.availableSizes[0];

                self.availableTags = [];
                self.selectedTag = '';

                // fetch data
                getData(function (err) {
                    if (err) {
                        //noinspection JSUnresolvedVariable
                        Notification.error({
                            message: 'Failed to retrieve videos ' + err.statusText
                        });
                        return;
                    }

                    populateSizes();
                    populateTags();
                    setTimeout(function () {
                        setVideoPosters();
                    }, 0);
                });
            };

            self.$onInit = function () {
                self.reload();
            };

            /**
             * @param {number} index
             * @returns {boolean}
             */
            self.isVisible = function (index) {
                /** @type{VideoDescription} */
                var videoDescription = self.data[index];
                /** @type {{width: number, height: number}} */
                var videoSize;
                /** @type {Array.<string>} */
                var videoTags;
                /** @type {string|{width: number, height: number}} */
                var selectedSize = self.selectedSize.value;
                /** @type {string} */
                var selectedTag = self.selectedTag;

                if (self.mode === 1) {
                    videoSize = videoDescription.templateData.size;
                    videoTags = videoDescription.templateData.tags;
                } else {
                    videoSize = videoDescription.description.size;
                    videoTags = videoDescription.description.tags;
                }

                return (selectedTag === '' || videoTags.reduce(function (acc, tag) {
                    return acc || tag.indexOf(selectedTag) > -1;
                }, false)) &&
                    (selectedSize === 'all' || (selectedSize.width === videoSize.width && selectedSize.height === videoSize.height));
            };

            /**
             * @param {number} index
             * @returns {number}
             */
            self.getWidth = function (index) {
                /** @type{VideoDescription} */
                var videoDescription = self.data[index];
                if (self.mode === 1) {
                    return videoDescription.templateData.size.width;
                }

                if (videoDescription.global.videoWidth !== undefined) {
                    // we display the size of the video, which might be different from the original template size
                    return videoDescription.global.videoWidth;
                }

                // otherwise we return the source template data
                return videoDescription.description.size.width;
            };

            /**
             * @param {number} index
             * @returns {number}
             */
            self.getHeight = function (index) {
                /** @type{VideoDescription} */
                var videoDescription = self.data[index];
                if (self.mode === 1) {
                    return videoDescription.templateData.size.height;
                }

                if (videoDescription.global.videoHeight !== undefined) {
                    // we display the size of the video, which might be different from the original template size
                    return videoDescription.global.videoHeight;
                }

                // otherwise we return the source template data
                return videoDescription.description.size.height;
            };

            /**
             * @param {number} index
             */
            self.play = function (index) {
                var video = $('#video-' + index).get(0);
                video.currentTime = 0;
                return video.play();
            };

            /**
             * @param {number} index
             */
            self.pause = function (index) {
                var video = $('#video-' + index).get(0);
                video.pause();
                video.currentTime = self.getThumbnailPosition(index);
            };

            /**
             * @param {number} index
             * @param {number} position
             */
            self.seek = function (index, position) {
                var video = $('#video-' + index).get(0);

                if (video === undefined) {
                    // If the element could not be found in the page, such as during the page initialization,
                    // we use setTimeout(..., 0) to wait until the page has finished executing all of the JS
                    // before trying again to find the element
                    window.setTimeout(function () {
                        video = $('#video-' + index).get(0);

                        if (video === undefined) {
                            console.error('Could not find video #' + index, 'in this page');
                        } else {
                            // IE does not allow to set the currentTime of an HTML5 media element if the media
                            // has not finished loading. In that case we must wait until the metadata are loaded
                            if (!isNaN(video.duration)) {
                                video.currentTime = position;
                            } else {
                                video.addEventListener('loadedmetadata', function () {
                                    video.currentTime = position;
                                });
                            }
                        }
                    }, 0);
                } else {
                    video.currentTime = position;
                }
            };

            /**
             * @param {number} index
             */
            self.selectTemplate = function (index) {
                /** @type{VideoDescription} */
                var videoDescription = self.data[index];
                $state.go('root.create', {
                    template: videoDescription.templateData.name,
                    description: videoDescription.templateData,
                    oldTemplate: videoDescription.configuration
                }, {location: true});
            };

            /**
             * @param {number} index
             */
            self.edit = function (index) {
                /** @type{VideoDescription} */
                var videoDescription = self.data[index];
                $state.go('root.create', {
                    template: videoDescription.template,
                    description: videoDescription.description,
                    oldTemplate: videoDescription
                });
            };

            /**
             * @param {number} index
             */
            self.delete = function (index) {
                /** @type{number} */
                var i;
                /** @type{string} */
                var videoId = self.data[index]._id;

                if (window.confirm(translate('LAST_CREATED.CONFIRM_DELETION'))) {
                    RestHttp.restPost('deleteVideo', {
                        videoId: videoId
                    }).then(function () {
                        Notification.success({message: translate('LAST_CREATED.VIDEO_SUCCESSFULLY_DELETED')});

                        self.data.splice(index, 1);
                        for (i = 0; i < self.data.length; i += 1) {
                            var videoElement = $('#video-' + i).get(0);

                            videoElement.load();
                            videoElement.poster = self.getThumbnailAsDataURL(i);
                        }
                    }, function (err) {
                        console.error(err);
                    });
                }
            };

            self.openTemplateCreationModal = function (index) {
                self.createTemplateData = {
                    videoId: self.data[index]._id,
                    filename: self.data[index].filename,
                    tags: self.data[index].description.tags.filter(function (tag) {
                        return tag !== BASE_TEMPLATE_TAG;
                    }) || [],
                    thumbnailPositionNormalized: self.data[index].description.thumbnailPosition / document.getElementById('video-' + index).duration
                };

                self.templateCreationModal = $uibModal.open({
                    templateUrl: 'templateCreationModal.html',
                    scope: $scope
                });

                self.templateCreationModal.rendered.then(function () {
                    setTimeout(function () {
                        self.updateModalVideoThumbnail();
                    }, 32);
                });

                self.updateModalVideoThumbnail();
            };

            self.createTemplateFromVideo = function () {
                if (self.templateCreationModal) {
                    self.templateCreationModal.close();
                }

                self.createTemplateData.thumbnailPositionNormalized = undefined;

                RestHttp.restPost('createTemplateFromVideo', self.createTemplateData).then(function () {
                    Notification.success({message: translate('SHARED_VIDEO_LIST.TEMPLATE_SUCCESSFULLY_CREATED')});
                }, function (err) {
                    Notification.error({message: translate('SHARED_VIDEO_LIST.TEMPLATE_CREATION_ERROR')});
                    console.error(err);
                });
            };

            self.rerenderVideo = function (index) {
                RestHttp.restPost('rerenderVideo', {
                    videoId: self.data[index]._id
                }).then(
                    function () {
                        Notification.success({message: translate('SHARED_VIDEO_LIST.VIDEO_RERENDERING_IN_PROGRESS')});
                    },
                    function () {
                        Notification.error({message: translate('SHARED_VIDEO_LIST.VIDEO_RERENDERING_ERROR')});
                    }
                );
            };

            self.updateModalVideoThumbnail = function () {
                var videoElement = document.getElementById('modalVideo');

                if (!videoElement) {
                    return;
                }

                videoElement.currentTime = self.createTemplateData.thumbnailPositionNormalized * videoElement.duration || 1.5;
                self.createTemplateData.thumbnailPosition = videoElement.currentTime;
            };

            self.getThumbnailPosition = function (index) {
                var defaultValue = 1.5;

                if (self.mode === 1) {
                    return (self.data[index].templateData.thumbnailPosition !== undefined ? self.data[index].templateData.thumbnailPosition : defaultValue);
                }

                if (self.mode === 2 || self.mode === 3) {
                    return (self.data[index].description.thumbnailPosition !== undefined ? self.data[index].description.thumbnailPosition : defaultValue);
                }

                return defaultValue;
            };

            self.clickOnElement = function (index) {
                if (self.mode === 1) {
                    self.selectTemplate(index);
                }
            };

            self.getThumbnailAsDataURL = function (index) {
                var base64Thumbnail = self.data[index].thumbnail;

                return 'data:image/jpeg;base64,' + base64Thumbnail;
            };

            self.setVideoPosters = function () {

            };

            /**
             * @param {number} index
             */
            self.editTemplateTags = function (index) {
                if (self.tagEditionModal) {
                    self.tagEditionModal.close();
                }

                var videoDescription = self.data[index];

                self.tagEditionData = {
                    videoDescription: videoDescription,
                    tags: videoDescription.templateData.tags.slice(),
                };

                self.tagEditionModal = $uibModal.open({
                    templateUrl: 'tagEditionModal.html',
                    scope: $scope
                });
            }

            self.saveTagEdition = function () {
                // This variables will get captured in the following callbacks in case another template's tags
                // are being edited while this one's server request is still pending
                var videoDescription = self.tagEditionData.videoDescription;
                var tags = self.tagEditionData.tags;

                self.tagEditionModal.close();

                RestHttp.restPost('editTemplateTags', {
                    templateId: videoDescription._id,
                    tags: tags,
                }).then(function () {
                    Notification.success({message: translate('SHARED_VIDEO_LIST.TAG_SUCCESSFULLY_UPDATED')});

                    videoDescription.templateData.tags = tags;
                }, function (err) {
                    Notification.error({message: translate('SHARED_VIDEO_LIST.TAG_UPDATE_ERROR')});
                });
            }

            /**
             * @param {VideoDescription} video
             */
            self.getVideoContainerCSSClass = function (video) {
                if (this.mode === 1 && video.isBaseTemplate) {
                    return 'base-template';
                }

                return '';
            };

            /**
             * @param {VideoDescription} video
             */
            self.promptForContentTemplateDeletion = function (video) {
                if (this.hasAdminRights() !== true || video.isBaseTemplate === true) {
                    return;
                }

                var deleteVideo = window.confirm($translate.instant('SHARED_VIDEO_LIST.CONTENT_TEMPLATE_CONFIRM_DELETION', {name: video.templateData.displayName}));

                if (deleteVideo === true) {
                    RestHttp.restPost('deleteContentTemplate', {templateId: video._id}).then(
                        /**
                         * @param {{data: string}} data
                         */
                        function () {
                            Notification.success({
                                message: $translate.instant('SHARED_VIDEO_LIST.CONTENT_TEMPLATE_DELETION_SUCCESS', {name: video.templateData.displayName})
                            });
                            self.reload();
                        },
                        function (err) {
                            console.error(err);
                            Notification.error({
                                message: $translate.instant('SHARED_VIDEO_LIST.CONTENT_TEMPLATE_DELETION_FAILURE', {name: video.templateData.displayName})
                            });
                        }
                    );
                }
            };


            /**
             * @returns {bool}
             */
            self.hasAdminRights = function () {
                return $rootScope.groups.indexOf('admin') !== -1;
            };
        }
    ]
};

/**
 * @typedef {Object} NameValue
 * @property {string} name
 * @property {*} value
 */

/**
 * @typedef {Object} SharedVideoListComponent
 *
 * Video controls
 * @property {function(number)} play
 * @property {function(number)} pause
 *
 * Specific to template mode
 * @property {function(number)} selectTemplate
 *
 * Specific to recent mode
 * @property {function(number)} edit
 * @property {function(number)} delete
 *
 * Data
 * @property {Array.<VideoDescription>} data
 * @property {number} mode 1: template, 2: self recent, 3: admin recent
 * @property {string} username 1: template, 2: self recent, 3: admin recent
 *
 * Filtering
 * @property {Array.<NameValue>} availableSizes
 * @property {NameValue} selectedSize
 * @property {Array.<NameValue>} availableTags
 * @property {NameValue} selectedTag
 * @property {function(number)} isVisible
 *
 * Misc
 * @property {function(number)} getWidth
 * @property {function(number)} getHeight
 */
