/*global angular, $*/
'use strict';

//noinspection JSUnusedGlobalSymbols
angular.module('login-screen').
    component('loginScreen', {
        templateUrl: './js/login-screen/login-screen.template.html',
        controller: [
            '$rootScope', '$scope', 'Notification', 'RestHttp',
            function loginScreenController($rootScope, $scope, Notification, RestHttp) {
                var self = this;
                self.sendLogin = function () {
                    RestHttp.restPost('getCredentialGroup', {
                        username: $scope.username,
                        password: $scope.password
                    }).then(function (data) {
                        if (data.err) {
                            $scope.username = '';
                            $scope.password = '';
                            Notification.error(
                                {message: 'Invalid username or password'}
                            );
                        } else {
                            document.cookie = 'token=' + data.token;
                            $rootScope.token = data.token;
                            document.cookie = 'username=' + data.username;
                            $rootScope.username = $scope.username;
                            document.cookie = 'groups=' + data.groups.join(',');
                            $rootScope.groups = data.groups;
                        }
                        // Safari bug fix !!! https://www.lifewire.com/safari-troubleshooting-use-re-render-menu-to-refresh-web-page-2260892
                        if (window.navigator.userAgent.indexOf('Safari') > -1) {
                            $scope.$applyAsync($rootScope.refresh);
                        }
                    }, function (resp) {
                        Notification.error(
                            {message: 'Failed to retrieve user\'s credential group.' + ' (' + resp.statusText + ')'}
                        );
                    });
                };

                function resetInvitationFields() {
                    $scope.i_username = '';
                    $scope.i_mail = '';
                    $scope.i_group = false;
                }

                self.sendInvitation = function () {
                    RestHttp.restPost('sendInvitation', {
                        username: $scope.i_username,
                        mail: $scope.i_mail,
                        group: $scope.i_group ? 'admin' : 'users'
                    }).then(function () {
                        Notification.success(
                            {message: 'Invitation send successfully.'}
                        );
                        resetInvitationFields();
                    }, function (resp) {
                        if (resp.status === 406) {
                            Notification.error(
                                {message: 'Error: user ' + $scope.i_username + ' already exists.'}
                            );
                            resetInvitationFields();
                        } else {
                            Notification.error(
                                {message: 'Failed to send the invitation.' + ' (' + ((resp.data && resp.data.err) || resp.statusText) + ')'}
                            );
                        }
                    });
                };

                self.$onInit = function () {
                    if ($rootScope.emailForPasswordReset) {
                        $scope.passwordResetEmail = $rootScope.emailForPasswordReset;
                        delete $rootScope.emailForPasswordReset;
                    }
                };
            }
        ]
    });
