'use strict';

var BaseElement = require('../baseElement');

/**
 * @param {*} $scope
 * @param {*} $stateParams
 * @param {*} UnityWebGL
 */
var GlobalElementController = function ($rootScope, $scope, $stateParams, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
};

GlobalElementController.prototype = Object.create(BaseElement.prototype);
GlobalElementController.constructor = GlobalElementController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/global-element/globalElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<',
        'standardPalette': '<'
    },

    controller: ['$rootScope', '$scope', '$stateParams', 'UnityWebGL', GlobalElementController]
};

GlobalElementController.timescales = [0.5, 1, 2, 3];

GlobalElementController.prototype.$onInit = function () {
    this.timescaleIndex = GlobalElementController.timescales.indexOf(this.value.timescale);

    this.unityCanvas = document.querySelector('canvas.emscripten');

    if (this.value.videoWidth === undefined) {
        this.value.videoWidth = parseInt(this.unityCanvas.width, 10);
    }
    if (this.value.videoHeight === undefined) {
        this.value.videoHeight = parseInt(this.unityCanvas.height, 10);
    }

    this.videoWidth = this.value.videoWidth;
    this.videoHeight = this.value.videoHeight;

    if (this.timescaleIndex === -1) {
        this.timescaleIndex = GlobalElementController.timescales.indexOf(1);
    }
};

GlobalElementController.prototype.hasAdminRights = function () {
    return this.$rootScope.groups.indexOf('admin') !== -1;
};

GlobalElementController.prototype.updateGlobal = function (additionalCommand) {
    if (this.value.videoWidth % 2 === 1 || this.value.videoHeight % 2 === 1) {
        return;
    }

    this.timescaleIndex = GlobalElementController.timescales.indexOf(this.value.timescale);

    if (this.timescaleIndex === -1) {
        this.timescaleIndex = GlobalElementController.timescales.indexOf(1);
    }

    this.sendElementToUnity(undefined, additionalCommand);
};

GlobalElementController.prototype.updateTimescale = function () {
    this.value.timescale = GlobalElementController.timescales[this.timescaleIndex] || GlobalElementController.timescales.indexOf(1);
    this.updateGlobal();
};

GlobalElementController.prototype.updateElement = function () {
    this.updateGlobal();
};

GlobalElementController.prototype.updateGlobalAndReplayTemplate = function () {
    this.updateGlobal('SceneReplay');
};

GlobalElementController.prototype.updateVideoDimensions = function () {
    if (this.videoWidth % 2 !== 0 || this.videoHeight % 2 !== 0) {
        return;
    }

    this.value.videoWidth = this.videoWidth;
    this.value.videoHeight = this.videoHeight;

    this.unityCanvas.width = this.value.videoWidth;
    this.unityCanvas.height = this.value.videoHeight;

    this.updateGlobal();

    if (this.$stateParams.template.indexOf('_canvas') !== -1) {
        this.createVideoController.updateImages();
    }
};

GlobalElementController.prototype.getTimescalesCount = function () {
    return GlobalElementController.timescales.length;
};