'use strict';

module.exports = [{
    name: 'list_users',
    collection: 'credentials',
    backend: 'mongo',
    edit: false,
    search: true,
    detailedView: false,
    id: '_id',
    nodes: [{
        name: 'DATABASE_EDITION.USER_LIST.USERNAME',
        view: 3,
        accessor: '_id',
        displayAs: 'readonly',
        sortable: true,
        searchable: true
    }, {
        name: 'DATABASE_EDITION.USER_LIST.MAIL_ADDRESS',
        view: 3,
        accessor: 'mail',
        displayAs: 'readonly',
        translationToDisplay: function (val) {
            if (!val) {
                return 'N/A';
            }

            return val;
        },
        sortable: true,
        searchable: true
    }, {
        name: 'DATABASE_EDITION.USER_LIST.GROUP',
        view: 3,
        saveAs: 'string',
        accessor: ['group', '0'],
        displayAs: 'readonly',
        sortable: true,
        searchable: true
    }, {
        name: 'DATABASE_EDITION.USER_LIST.REGISTERED_AT',
        view: 3,
        accessor: 'initialized',
        displayAs: 'date',
        translationToDisplay: function (val) {
            if (val === 0) {
                return 'N/A';
            }

            return val;
        },
        sortable: true,
        searchable: true
    }, {
        name: 'DATABASE_EDITION.USER_LIST.VIDEOS',
        view: 3,
        displayAs: 'component',
        accessor: 'goToLastCreated'
        // }, {
    //     name: 'Initialized',
    //     view: 3,
    //     accessor: ['id', {accessor: 'id', collection: 'sample_details'}, 'gold'],
    //     saveAs: 'number',
    //     displayAs: 'number'
    }, {
        name: 'DATABASE_EDITION.USER_LIST.DELETE',
        view: 3,
        displayAs: 'component',
        accessor: 'deleteUserButton'
    }]
}];
