'use strict';

var BaseElement = require('../baseElement');

/** */
var VideoElementController = function ($scope, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    this.$scope = $scope;
};

VideoElementController.prototype = Object.create(BaseElement.prototype);
VideoElementController.constructor = VideoElementController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/video-element/videoElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<'
    },

    controller: ['$scope', 'UnityWebGL', VideoElementController]
};

VideoElementController.prototype.$onInit = function () {return;};

/**
 * @param {string} key
 * @param {*} file
 */
VideoElementController.prototype.updateVideo = function (key, file) {
    this.createVideoController.updateVideo(key, file);
};
