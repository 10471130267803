module.exports =[
    ["#f98766","#ff410d","#80bd9d","#88d958"],
    ["#8fafc4","#336a86","#283031","#753524"],
    ["#45201a","#693c3c","#b95436","#a33720"],
    ["#324750","#86ab40","#33665c","#7da2a1"],
    ["#003b45","#07575b","#66a4ac","#c3dee5"],
    ["#2e4500","#476a00","#a2c422","#7d4426"],
    ["#011b1d","#004445","#2b7873","#6fb98f"],
    ["#375d96","#fa6541","#ffba00","#3f671b"],
    ["#98dac6","#5bc7ab","#e5d629","#f18c9d"],
    ["#324750","#86ab40","#33665c","#7da2a1"],
    ["#4bb4f5","#b6b8b5","#203f49","#b3c100"],
    ["#f3cc6f","#de7921","#20948b","#6ab086"],
    ["#8c230e","#1d424b","#9a4e0e","#c89d0f"],
    ["#f1f2f2","#bbb9be","#a1d5e2","#1894ac"],
    ["#999dab","#5c535d","#ec95a3","#dee066"],
    ["#001a27","#043752","#f0810d","#e5de44"],
    ["#74b0a8","#d9b44a","#4e6457","#abd0bf"],
    ["#eb8a44","#f9db24","#4a7346","#8eb942"],
    ["#363237","#2d4161","#73605b","#d09582"],
    ["#f52549","#fa6674","#ffd54d","#9abf1a"],
    ["#2e2300","#6e6601","#bf5804","#da9400"],
    ["#4f312e","#cb0000","#e3e98b","#3f6b45"],
    ["#33878b","#7caa2d","#f5e355","#cb6217"],
    ["#0e1b06","#ffffff","#5c811a","#c6d066"],
    ["#00293c","#1d656c","#f1f2cd","#f52900"],
    ["#616c70","#ccccbf","#ddbb94","#b38766"],
    ["#258038","#f5be40","#31a8b8","#cf3720"],
    ["#ed693f","#f59453","#fafcfd","#739e3c"],
    ["#b9d9c2","#742907","#facb7a","#eb5d2f"],
    ["#1d1f25","#283654","#4c638c","#d0e0f9"],
    ["#f60025","#f6eee2","#f15c00","#f9a603"],
    ["#a1be94","#e2dea2","#91aac7","#ec5752"],
    ["#4796d7","#ffda5c","#fa6e58","#f79f54"],
    ["#af4424","#662e1b","#ebdbb1","#c8a66a"],
    ["#c1e0db","#ffccab","#ffeb94","#fcd474"],
    ["#4b3f53","#d03425","#f1bf57","#476724"],
    ["#faaf08","#fa812e","#fa4032","#fef3e2"],
    ["#f3ec6a","#bacf4a","#e73f0a","#a11f0b"],
    ["#fff2e3","#fc964e","#c60000","#80593b"],
    ["#f67504","#b8d10a","#f56b57","#231b12"],
    ["#d7402e","#fe7946","#fcfcfe","#f5c999"],
    ["#e9e1d5","#d5c2aa","#867566","#e0b80d"],
    ["#245c00","#67a225","#b3de81","#fcffff"],
    ["#a10015","#d62b16","#f0efea","#bfb1b4"],
    ["#c7da00","#79a701","#f68b2d","#e3b500"],
    ["#2f1b28","#523633","#b5452b","#ddc4a2"],
    ["#ebde00","#7d7a15","#553d20","#b38540"],
    ["#662125","#b41c09","#e9d29c","#7d5d3c"],
    ["#4a4245","#546cab","#f69a77","#745247"],
    ["#d7402e","#fe7946","#fcfcfe","#f5c999"],
    ["#2987bb","#2e496e","#f3e9de","#ec8b72"],
    ["#000928","#d60025","#f6f4f0","#ecb83c"],
    ["#1c0000","#4f0803","#9d331f","#bb6c4e"],
    ["#f9b931","#416e86","#f7f1e4","#2e3131"],
    ["#061c28","#2e3e41","#5b6f65","#c8d0c7"],
    ["#d14036","#eb8a3d","#ebb481","#785945"],
    ["#207ca2","#e2992f","#32374d","#201f2f"],
    ["#004d46","#118177","#52948b","#b9c3c8"],
    ["#4f6c2e","#282820","#f2ebdd","#7d5541"],
    ["#f37d4a","#e0315b","#ffec5c","#008ccb"],
    ["#a3c9bb","#e9b364","#b1463d","#abbd78"],
    ["#15243c","#002b53","#eeb409","#cc7212"],
    ["#8593ad","#594e4d","#7d665d","#dda287"],
    ["#2a616c","#b1dad5","#ffffff","#fa8c61"],
    ["#00cffa","#ff0037","#ffcd37","#010308"],
    ["#a4c2cf","#f2d2b8","#e49d5c","#a89e3c"],
    ["#257885","#5da7a6","#ffffff","#ff4446"],
    ["#fbc774","#b9a795","#e5ccb4","#e38b74"],
    ["#335252","#d4dde0","#aa4a40","#2d2f33"],
    ["#ffccba","#6eb4bf","#006b83","#e2e7e3"],
    ["#8b0003","#c70008","#e7a634","#e2c399"],
    ["#2a4a52","#536f72","#8e9a96","#f3ebda"],
    ["#c4001a","#e3e3da","#103742","#c4beb9"],
    ["#d25c37","#be9977","#d5c6b9","#96b8c2"],
    ["#909535","#524a39","#fef9df","#595e37"],
    ["#528f8b","#e4e2c9","#472d33","#e7462e"],
    ["#2d2d33","#d5d5d1","#ffffff","#395099"],
    ["#746766","#dbd9d4","#343c3f","#ff8c3f"],
    ["#30a2ab","#af1a1a","#f0eff0","#2e2e28"],
    ["#6b5e5b","#ccab81","#d9c2b3","#4e4a45"],
    ["#444b5c","#cd5957","#78a4a2","#e0b06a"],
    ["#1e2228","#abbebe","#f3f3ee","#9f1c25"],
    ["#d55347","#ffa477","#f0f8fa","#886e69"],
    ["#324d8f","#5cc4ee","#ffb645","#e7542b"],
    ["#060604","#ececec","#d0b180","#584d45"],
    ["#5e958e","#bedbcf","#df5858","#d5c8b0"],
    ["#952615","#ffffff","#202125","#bac2c6"],
    ["#678b8a","#9fb083","#f9edd3","#a47c65"],
    ["#242e33","#f24a4a","#f1d2bb","#614f49"],
    ["#872324","#ccbea6","#322f2f","#c29445"],
    ["#413139","#6b2c2a","#9e4536","#f0dac6"],
    ["#facc4a","#a2a499","#272422","#87a44f"],
    ["#ffbebd","#fdfdfc","#337aad","#1a405e"],
    ["#0e1f37","#8e786f","#f55349","#1a4a59"],
    ["#81705d","#faad3c","#e38533","#e3535d"],
    ["#051282","#fc3c3c","#ffb64b","#128c8f"],
    ["#dcdcdd","#232021","#a4bf5b","#7aa3a7"],
    ["#b3dabf","#fe0000","#fdf5f5","#66b9c9"],
    ["#a39491","#726f77","#edd7c8","#e89686"],
    ["#478a99","#daad58","#4b585c","#b4b4b4"]
  ];
