/*global angular*/
'use strict';

angular.
    module('core.google-translate').
    factory('GoogleTranslate', [
        '$http', '$log', '$q',
        function ($http, $log, $q) {
            /** @type{Object.<Array.<Language>>} */
            var languageList = {};

            /**
             * Returns a promise with all the agents
             * @param {string} language
             * @returns {Promise}
             */
            function getLanguageList(language) {
                var deferred = $q.defer();

                if (languageList[language]) {
                    deferred.resolve(languageList[language]);
                } else {
                    $http.get('./rest/getLanguages', {
                        params: {
                            language: language
                        }
                    }).then(function successCallback(res) {
                        languageList[language] = res.data;
                        deferred.resolve(languageList[language]);
                    }, function errorCallback(resp) {
                        $log.error('Failed to retrieve the agents list (' + resp.statusText + ')');
                        deferred.reject(resp);
                    });
                }

                return deferred.promise;
            }

            function translate(languageSource, languageDestination, text) {
                var deferred = $q.defer();
                var texts;

                if (languageSource === languageDestination) {
                    deferred.resolve(text);
                } else {
                    $http.post('./rest/translate', {
                        from: languageSource,
                        to: languageDestination,
                        texts: text.split('\n')
                    }).then(function successCallback(res) {
                        deferred.resolve(res.data.join('\n'));
                    }, function errorCallback(resp) {
                        deferred.reject(resp);
                    });
                }

                return deferred.promise;
            }

            return {
                getLanguageList: getLanguageList,
                translate: translate
            };
        }
    ]);

/**
 * @typedef {Object} Language
 * @property {string} code
 * @property {string} name
 */
