/**
 * @param {*} $scope Angular scope of the element
 */
var BaseElement = function ($scope, UnityWebGL) {
    $scope.$parent.$parent.$parent.$ctrl.elementControllers.push(this);

    this.$scope = $scope;
    this.UnityWebGL = UnityWebGL;

    this.effectControllers = [];
};

/**
 *
 * @param {object} object
 */
function deleteNullValues(object) {
    /** @type {Array.<string>} */
    var propertyNames = Object.getOwnPropertyNames(object);
    /** @type {number} */
    var i;
    /** @type {any} */
    var value;

    if (Array.isArray(object)) {
        for (i = 0; i < object.length; i += 1) {
            value = object[i];

            if (typeof (value) === 'object') {
                deleteNullValues(value)
            }
        }

        return;
    }

    for (i = 0; i < propertyNames.length; i += 1) {
        var propertyName = propertyNames[i];
        value = object[propertyName];

        if (value === null) {
            delete object[propertyName];
        } else if (typeof (value) === 'object') {
            deleteNullValues(value);
        }
    }
}

var ELEMENT_SENDING_INTERVAL_IN_MS = 500;
var elementToSendToUnity = {};
var elementToSendToUnityInterval = {};

for (var i = 0; i < 100; ++i) {
    elementToSendToUnityInterval[i] = true;
}

function sendElementToUnity(UnityWebGL, index, value, updateEffects, additionalCommand, forceSend) {
    // If the call comes from this function's logic, send the data and reset the timer
    if (forceSend === true || elementToSendToUnityInterval[index] === true) {
        UnityWebGL = (UnityWebGL === undefined ? elementToSendToUnity[index].UnityWebGL : UnityWebGL);
        value = (value === undefined ? elementToSendToUnity[index].value : value);
        updateEffects = (updateEffects === undefined ? elementToSendToUnity[index].updateEffects : updateEffects);
        additionalCommand = (elementToSendToUnity[index] !== undefined ? elementToSendToUnity[index].additionalCommand : additionalCommand);

        console.log('SENDING ELEMENT', index, value, updateEffects, additionalCommand);
        UnityWebGL.sendElement(index, value, updateEffects);

        if (additionalCommand !== undefined) {
            UnityWebGL.sendCommand(additionalCommand);
        }

        delete elementToSendToUnity[index];
        delete elementToSendToUnityInterval[index];

        return;
    }

    // If the timer is not set yet, arm it and store the state to send
    if (elementToSendToUnityInterval[index] === undefined) {
        elementToSendToUnity[index] = {
            UnityWebGL, index, value, updateEffects, additionalCommand
        };
        elementToSendToUnityInterval[index] = setTimeout(
            sendElementToUnity,
            ELEMENT_SENDING_INTERVAL_IN_MS,
            undefined, index, undefined, undefined, undefined, true
        );

        return;
    }

    // Here, the call comes from outside of this function's logic and the timer is armed for the current event.
    // Just replace the stored data and let the timer run out
    clearTimeout(elementToSendToUnityInterval[index]);
    elementToSendToUnity[index] = { UnityWebGL, index, value, updateEffects, additionalCommand };
    elementToSendToUnityInterval[index] = setTimeout(
        sendElementToUnity,
        ELEMENT_SENDING_INTERVAL_IN_MS,
        undefined, index, undefined, undefined, undefined, true
    );
}

var COMMAND_SENDING_INTERVAL_IN_MS = 500;
var commandTimeoutId;

function sendCommandWithTimeBuffer(UnityWebGL, command) {
    if (commandTimeoutId !== undefined) {
        clearTimeout(commandTimeoutId);
        commandTimeoutId = undefined;
    }

    commandTimeoutId = setTimeout(function () {
        UnityWebGL.sendCommand(command);
        commandTimeoutId = undefined;
    }, COMMAND_SENDING_INTERVAL_IN_MS);
}

BaseElement.prototype.getEffectName = function (effectType) {
    return 'EFFECTS.' + effectType.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
};

BaseElement.prototype.sendElementToUnity = function (updateEffects, additionalCommand) {
    if (!this.UnityWebGL.isUnityReady()) {
        return;
    }

    deleteNullValues(this.value);

    //sendElementToUnity(this.UnityWebGL, this.value.index, this.value, !!updateEffects, additionalCommand);

    this.UnityWebGL.sendElement(this.value.index, this.value, !!updateEffects);

    if (additionalCommand !== undefined) {
        sendCommandWithTimeBuffer(this.UnityWebGL, additionalCommand);
    }
};

BaseElement.prototype.updateElement = function (updateEffects, additionalCommand) {
    this.sendElementToUnity(updateEffects, additionalCommand);
};

module.exports = BaseElement;
