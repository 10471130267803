'use strict';

var BaseElement = require('../baseElement');

/**
 * @param {number} nbr
 */
function numberToHex(nbr) {
    return ('0' + nbr.toString(16)).slice(-2); // to handle the one digit case
}

/**
 * @param {string} color hex-rgb color
 * @param {number} alpha alpha value in the range [0;100]
 */
function mergeColorSlider(color, alpha) {
    return color + numberToHex(alpha);
}

/**
 * @param {string} color hex-rgba color
 */
function splitColorSlider(color) {
    return [color.slice(0, -2), Math.round(100 * parseInt(color.slice(-2), 16) / 255)];
}

var BackgroundElementController = function ($scope, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    var self = this;

    this.$scope = $scope;

    this.rgbColor = '#ffffff';
    this.alpha = 50;

    Object.defineProperty(this, 'value', {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;

            if (value !== undefined) {
                self.color = self.value.color;

                // Object.defineProperty is available in all modern browsers (even mobile) and IE9+
                Object.defineProperty(value, 'color', {
                    get: function () {
                        return self.color;
                    },
                    set: function (newColorValue) {
                        self.color = newColorValue;
                        self.updateColor(newColorValue);
                    }
                });

                // Use it to trigger the above
                self.value.color = self.color;
            } else {
                self.rgbColor = '#ffffff';
                self.alpha = 50;
            }
        }
    });
};

BackgroundElementController.prototype = Object.create(BaseElement.prototype);
BackgroundElementController.constructor = BackgroundElementController;

/**
 * @param {string} color hex-rgba color
 */
BackgroundElementController.prototype.updateColor = function (color) {
    if (typeof (color) !== 'string') {
        return;
    }

    var split = splitColorSlider(color);

    this.rgbColor = split[0];
    this.alpha = split[1];
};

/** */
BackgroundElementController.prototype.updateBackground = function () {
    // Note: the mapping from 0-255 to 0-100 is done here and in the createVideoController
    // and not in the C# code to preserve the sending of a single color parameter for
    // each update (because the refactoring would otherwise have been larger)
    if (this.numberInput === undefined) {
        this.numberInput = document.getElementById('transparency-' + this.$scope.$id);
    }

    this.value.color = mergeColorSlider(this.rgbColor, Math.floor(255 * (this.alpha || 0) / 100));

    this.sendElementToUnity();
};

BackgroundElementController.prototype.updateElement = function () {
    this.updateBackground();
};

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/background-element/backgroundElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<',
        'standardPalette': '<'
    },

    controller: ['$scope', 'UnityWebGL', BackgroundElementController]
};
