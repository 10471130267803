/* globals window, document, URL, Uint8Array, atob, Blob */
'use strict';

var BaseElement = require('../baseElement');

var ImageElementController = function ($scope, RestHttp, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    this.$scope = $scope;
    this.RestHttp = RestHttp;
};

ImageElementController.prototype = Object.create(BaseElement.prototype);
ImageElementController.constructor = ImageElementController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/image-element/imageElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<'
    },

    controller: ['$scope', 'RestHttp', 'UnityWebGL', ImageElementController]
};

/**
 * @param {string} dataURL
 */
function createBlobFromDataURL(dataURL) {
    var urlSplits = dataURL.split(',');
    var bytes = (urlSplits[0].indexOf('base64') !== -1 ? atob : unescape)(urlSplits[1]);
    var bytesArray = new Uint8Array(bytes.length);
    var mimeType = urlSplits[0].split(':')[1].split(';')[0];

    bytes.split('').forEach(function (byte, i) {
        bytesArray[i] = byte.charCodeAt(0);
    });

    return new Blob([bytesArray], {type: mimeType});
}

ImageElementController.prototype.$onInit = Function.prototype;

/**
 * @param {string} key
 * @param {File} file
 */
ImageElementController.prototype.updateImage = function (key, file) {
    var self = this;
    var url = (file ? URL.createObjectURL(file) : '');

    if (file) {
        self.value.file = file;
        self.resizeImage(file, function (blob) {
            delete self.value.assetId;
            self.value.url = URL.createObjectURL(blob);
            self.sendElementToUnity();
        });
    } else if (self.value.assetId) {
        self.RestHttp.restPost('retrieveAssetData', {
            assetId: self.value.assetId
        }).then(function (assetData) {
            /** @type {number} */
            var i;
            var binaryContent = window.atob(assetData.asset);
            var u8arr = new window.Uint8Array(binaryContent.length);

            for (i = 0; i < binaryContent.length; i += 1) {
                u8arr[i] = binaryContent.charCodeAt(i);
            }

            /** @type {File} */
            var inMemoryFile = new window.File([u8arr], assetData.filename, {type: assetData.type});

            self.resizeImage(inMemoryFile, function (blob) {
                self.value.file = blob;
                self.value.url = URL.createObjectURL(blob);

                self.sendElementToUnity();
            });
        }, function (err) {
            console.error(err);
        });
    } else {
        if (typeof (url) === 'string' && url !== '') {
            self.value.url = url;
        }

        self.sendElementToUnity();
    }
};

ImageElementController.prototype.updateElement = function () {
    this.updateImage();
};

/**
 * @param {File} file
 * @param {function} callback
 */
ImageElementController.prototype.resizeImage = function (file, callback) {
    var image = new Image();
    image.addEventListener('load', function () {
        var canvas = document.createElement('canvas');
        document.body.appendChild(canvas);

        var reductionRatio = Math.min(1, 300 / Math.max(image.width, image.height));

        canvas.width = image.width * reductionRatio;
        canvas.height = image.height * reductionRatio;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        document.body.removeChild(canvas);

        callback(createBlobFromDataURL(canvas.toDataURL()));
    });
    image.src = URL.createObjectURL(file);
};

/**
 * @param {string} angle (in degress)
 */
ImageElementController.prototype.rotateImage = function (angle) {
    this.value.rotation = (this.value.rotation + angle) % 360;

    return this.updateImage(this.key);
};
