'use strict';

var BaseElement = require('../baseElement');

var PhoneNumberElementController = function ($scope, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    this.$scope = $scope;
};

PhoneNumberElementController.prototype = Object.create(BaseElement.prototype);
PhoneNumberElementController.constructor = PhoneNumberElementController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/phone-number-element/phoneNumberElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<',
        'standardPalette': '<'
    },

    controller: ['$scope', 'UnityWebGL', PhoneNumberElementController]
};

PhoneNumberElementController.prototype.$onInit = function () {
    this.updateElement(this.key);

    this.selectedLanguageCode = this.createVideoController.languageList[0].code;
};

/**
 * @param {string} key
 * Kept in the createVideoController because the text needs
 * to be initialized as soon as the template is loaded
 */
PhoneNumberElementController.prototype.updatePhoneNumber = function () {
    this.formatDisplayText();
    this.value.dilate = this.value.outlineSize;

    this.sendElementToUnity();
};

PhoneNumberElementController.prototype.updateElement = function () {
    this.updatePhoneNumber();
};

PhoneNumberElementController.prototype.formatDisplayText = function () {
    var self = this;
    var splits = this.value.inputText.split('\n');
    var displayText;

    if (splits.length > 3) {
        var firstLine = splits.shift();
        var secondLine = splits.shift();

        displayText = [firstLine, secondLine, splits.join(' ')].join('\n');
    } else {
        displayText = this.value.inputText;
    }

    this.value.inputText = displayText;
    setTimeout(function () {
        self.value.inputText = displayText;
    }, 1);
};
