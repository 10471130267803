/*global angular, $*/
'use strict';

var TagListEditorController = function ($scope, $element) {
    this.$scope = $scope;
    this.$element = $element;

    this.inputElement = this.$element[0].querySelector('input');
    this.$element[0].addEventListener('compositionstart', () => {
        //this.validateTag(this.inputElement);
    });
};

TagListEditorController.prototype.$onInit = function () {
    var expression = this.$element.attr('auto-complete-tags');

    this.$scope.$parent.$watch(expression, () => {
        console.log('Rebuilding available tags with', this.autoCompleteTags, arguments);
        this.availableTags = (this.autoCompleteTags || []).map(t => ({name: t}));
    }, true);
}

TagListEditorController.prototype.tagInputKeypress = function ($event) {
    if ($event.which == 13) {
        this.validateTag($event.target);
    }
};

TagListEditorController.prototype.validateTag = function (inputElement) {
    if (this.tags.indexOf(inputElement.value) === -1 && inputElement.value.trim().length > 0) {
        this.tags.push(inputElement.value);
    }

    inputElement.value = '';
}

TagListEditorController.prototype.onTypeaheadSelect = function(item) {
    this.inputElement.value = item.name;

    this.validateTag(this.inputElement);

    setTimeout(() => this.inputElement.value = '', 0);
}


module.exports = {
    templateUrl: './js/shared-video-list/components/tag-list-editor/tag-list-editor.template.html',
    controller: [
        '$scope',
        '$element',
        TagListEditorController
    ],
    bindings: {
        tags: '<',
        autoCompleteTags: '<',
    }
};
