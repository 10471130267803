'use strict';

var BaseEffect = require('./baseEffect');

/**
 * @param {*} $scope
 */
var MultipleChoiceAppearanceEffect = function ($scope) {
    BaseEffect.apply(this, [$scope]);

    this.$scope = $scope;
};

MultipleChoiceAppearanceEffect.prototype = Object.create(BaseEffect.prototype);
MultipleChoiceAppearanceEffect.constructor = MultipleChoiceAppearanceEffect;

module.exports = {
    templateUrl: './js/create-video/bitcraft-effect-library/multipleChoiceAppearanceEffect.template.html',
    bindings: {
        'elementIndex': '<',
        'effectIndex': '<',
        'value': '<',
        'createVideoController': '<'
    },

    controller: ['$scope', MultipleChoiceAppearanceEffect]
};

MultipleChoiceAppearanceEffect.prototype.$onInit = function () {};

MultipleChoiceAppearanceEffect.prototype.updateMultipleChoiceAppearanceEffect = function () {
    this.parentElementController.updateElement(undefined, 'SceneReplay');
};

MultipleChoiceAppearanceEffect.prototype.updateEffect = function () {
    this.updateMultipleChoiceAppearanceEffect();
};

MultipleChoiceAppearanceEffect.prototype.updateActiveEffect = function () {
    switch (this.value.activeEffect) {
        case 'SCROLL':
            this.value.duration = 1;
            break;
        case 'FADE_IN_OUT':
            this.value.duration = 1;
            break;
        case 'STRETCH':
            this.value.duration = 1;
            break;
        case 'ZOOM':
            // TODO: FIXME: this is a quick and dirty way to set different default values for the image zoom
            // and text zoom used in Template05_canvas.
            // We should have a better way to tell inside of which type of element we are, and a better way to set
            // those default value in the first place
            this.value.duration = (this.parentElementController.updateText ? 1 : 8);
            break;
    }

    this.updateMultipleChoiceAppearanceEffect();
};

MultipleChoiceAppearanceEffect.prototype.updateScrollDirection = function () {
    this.value.reverseDirection = (this.value.horizontalAxis ? !this.value.reverseDirection : this.value.reverseDirection);
    this.value.horizontalAxis = !this.value.horizontalAxis;

    this.updateEffect();
};

MultipleChoiceAppearanceEffect.prototype.getDurationTextKey = function () {
    switch (this.value.activeEffect) {
        case 'SCROLL':
            return 'CREATE_VIDEO.SCROLL_DURATION';
        case 'FADE_IN_OUT':
            return 'CREATE_VIDEO.FADE_IN_DURATION';
        case 'STRETCH':
            return 'CREATE_VIDEO.STRETCH_DURATION';
        case 'ZOOM':
            return 'CREATE_VIDEO.ZOOM_DURATION';
    }

    return '';
};

MultipleChoiceAppearanceEffect.prototype.getScrollingDirectionClass = function () {
    if (this.value.horizontalAxis) {
        if (this.value.reverseDirection) {
            return 'fa-arrow-right';
        }

        return 'fa-arrow-left';
    }

    if (this.value.reverseDirection) {
        return 'fa-arrow-up';
    }

    return 'fa-arrow-down';
};
