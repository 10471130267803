/**
 * @param {*} $scope Angular scope of the element
 */
var BaseEffect = function ($scope) {
    this.parentElementController = $scope.$parent.$ctrl;

    this.parentElementController.effectControllers.push(this);
};

BaseEffect.prototype.updateEffect = function () {
    this.parentElementController.updateElement(true);
};

module.exports = BaseEffect;
