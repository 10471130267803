/*global angular, $*/
'use strict';

//noinspection JSUnusedGlobalSymbols
angular.module('register').
    component('register', {
        templateUrl: './js/login-screen/register/register.template.html',
        controller: [
            '$scope', '$stateParams', '$state', 'Notification', 'RestHttp',
            function loginScreenController($scope, $stateParams, $state, Notification, RestHttp) {
                //noinspection JSUnusedGlobalSymbols
                this.$onInit = function () {
                    RestHttp.restPost('verifyToken', {
                        token: $stateParams.token
                    }).then(function (data) {
                        $scope.username = data;
                    }, function (resp) {
                        if (resp.status === 406) {
                            Notification.error(
                                {message: 'This link has expired or is invalid.'}
                            );
                            $state.go('^');
                        } else {
                            Notification.error(
                                {message: 'Failed to verify your token.' + ' (' + resp.statusText + ')'}
                            );
                        }
                    });
                };

                var self = this;

                self.register = function () {
                    RestHttp.restPost('registerPassword', {
                        token: $stateParams.token,
                        password: $scope.password
                    }).then(function () {
                        Notification.success(
                            {message: 'Your password has been register successfully. You can now log in.'}
                        );
                        $state.go('^');
                    }, function (resp) {
                        if (resp.status === 406) {
                            Notification.error(
                                {message: 'This link has expired or is invalid.'}
                            );
                            $state.go('^');
                        } else {
                            Notification.error(
                                {message: 'Failed to register your password.' + ' (' + resp.statusText + ')'}
                            );
                        }
                    });
                };
            }
        ]
    });