/*global angular, URL, SendMessage, UnityWebGL*/
'use strict';

// The communication between Unity and the browser is handled here
// It is asymmetric
// browser -> Unity: SendMessage
// Unity -> browser: UnityWebGL
// SendMessage is a method that sends a message to Unity. It can only send strings, thus we need to parse them later on in C#
// UnityWebGL is a global variable, we define methods on this object here, and we will call it from C#.
// We also only communicate by passing strings on these methods.

module.exports = function () {
    /** @type{boolean} */
    var isReady = false;
    /** @type{Array.<function()>} */
    var waitingReady = [];
    /** @type{Array.<function(Array<Number>)>} */
    var listeningHighlight = [];
    /** @type{Array.<function(string)>} */
    var listeningTemplateReady = [];
    /** @type{Array.<function(number)>} */
    var listeningLastFrameNumber = [];
    /** @type{NettenOptions|null} */
    var options = null;
    /** @type{string} */
    var delimiter = '=$@;';

    /**
     * @returns {NettenOptions}
     */
    function getOptions() {
        return options;
    }

    /**
     * Check if unity is ready
     * @returns {boolean}
     */
    function isUnityReady() {
        return isReady;
    }

    /**
     * @param {function()} callback
     */
    function onUnityReady(callback) {
        if (isReady) {
            callback();
        }
        waitingReady.push(callback);
    }

    function updateLastFrameNumber() {
        SendMessage('EventManager', 'GetLastFrameNumber');
    }

    /**
     * @param {string} template (must match the templates enum in the c# code)
     */
    function sendChangeTemplate(template) {
        if (!isReady) {
            throw new Error('Unity was not ready.');
        }

        SendMessage('EventManager', 'OnTemplateChanged', template);

        updateLastFrameNumber();
    }

    /**
     * @param {string} command (must match the command enum in the c# code)
     */
    function sendCommand(command) {
        if (!isReady) {
            throw new Error('Unity was not ready.');
        }

        SendMessage('EventManager', 'OnExecuteCommand', command);

        updateLastFrameNumber();
    }

    /**
     * @param {number} elementIndex
     * @param {string} element
     */
    function sendElement(elementIndex, element) {
        if (isReady !== true) {
            console.error('Unity is not ready yet');
            return;
        }

        var jsonConfToSend = elementIndex + JSON.stringify(element);

        console.groupCollapsed('Sending new configuration');
        console.log(jsonConfToSend);
        console.groupEnd();

        SendMessage('EventManager', 'OnUpdateElement', jsonConfToSend);

        updateLastFrameNumber();
    }

    /**
     * @param {function(Array<number>)} cb
     */
    function registerListenHighlights(cb) {
        listeningHighlight.push(cb);
    }

    /**
     * @param {function(Array<number>)} cb
     */
    function unregisterListenHighlights(cb) {
        var i;
        for (i = 0; i < listeningHighlight.length; i += 1) {
            if (listeningHighlight[i] === cb) {
                listeningHighlight.splice(i, 1);
                return;
            }
        }
    }

    /**
     * @param {function} cb
     */
    function registerTemplateReady(cb) {
        listeningTemplateReady.push(cb);
    }

    /**
     * @param {function} cb
     */
    function unregisterTemplateReady(cb) {
        var i;
        for (i = 0; i < listeningTemplateReady.length; i += 1) {
            if (listeningTemplateReady[i] === cb) {
                listeningTemplateReady.splice(i, 1);
                return;
            }
        }
    }

    /**
     * @param {function} cb
     */
    function registerLastFrameNumber(cb) {
        listeningLastFrameNumber.push(cb);
    }

    /**
     * @param {function} cb
     */
    function unregisterLastFrameNumber(cb) {
        var i;
        for (i = 0; i < listeningLastFrameNumber.length; i += 1) {
            if (listeningLastFrameNumber[i] === cb) {
                listeningLastFrameNumber.splice(i, 1);
                return;
            }
        }
    }

    /**
     * @param {string} config
     */
    UnityWebGL.unityReady = function (config) {
        var i;
        options = JSON.parse(config);
        isReady = true;
        for (i = 0; i < waitingReady.length; i += 1) {
            waitingReady[i]();
        }
        waitingReady = [];
    };

    /**
     * @param {string} config
     */
    UnityWebGL.templateReady = function (config) {
        // we don't need to check if the template was already ready, because we register
        // the callback for this event BEFORE calling sendChangeTemplate.
        config = config.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");

        var i;
        for (i = 0; i < listeningTemplateReady.length; i += 1) {
            listeningTemplateReady[i](config);
        }
    };

    /**
     * @param {Array<Number>} indexes
     */
    UnityWebGL.highlightIndexes = function (indexes) {
        var i;
        for (i = 0; i < listeningHighlight.length; i += 1) {
            listeningHighlight[i](indexes);
        }
    };

    UnityWebGL.sendLastFrameNumber = function (frameNumber) {
        var i;
        for (i = 0; i < listeningLastFrameNumber.length; i += 1) {
            listeningLastFrameNumber[i](frameNumber);
        }
    };

    return {
        getOptions: getOptions,

        isUnityReady: isUnityReady,
        onUnityReady: onUnityReady,

        sendChangeTemplate: sendChangeTemplate,
        sendCommand: sendCommand,
        sendElement: sendElement,

        registerListenHighlights: registerListenHighlights,
        unregisterListenHighlights: unregisterListenHighlights,

        registerTemplateReady: registerTemplateReady,
        unregisterTemplateReady: unregisterTemplateReady,

        registerLastFrameNumber: registerLastFrameNumber,
        unregisterLastFrameNumber: unregisterLastFrameNumber
    };
};

/**
 * @typedef {Object} KeyValue
 * @property {string} key
 * @property {string} value
 */

/**
 * @typedef {Object} NettenOptions
 * @property {Array<string>} fonts
 * @property {Array<KeyValue>} horizontalFits
 * @property {Array<KeyValue>} verticalFits
 * @property {Array<KeyValue>} imageFits
 * @property {Array<KeyValue>} videoFits
 * @property {Array<KeyValue>} scrollDirections
 */
