module.exports = {
    'Template02': {
        questions: [
            {
                questionId: 0,
                paths: ['[4].inputText', '[4].displayText'],
                promptText: '点滅してる部分の文章を指定できるよ。4文字、もしくは8文字がベストだよ。',
                type: 'text',
                placeholderValue: 'お店の名前',
                highlights: {
                    left: 0, right: 1,
                    top: 0.21, bottom: 0.8,
                    color: '#ff0000'
                }
            },
            {
                questionId: 1,
                paths: ['[6].inputText', '[6].displayText'],
                promptText: '点滅してる部分の文章を指定できるよ。ここはスクロールするから長い文章でもOK！',
                type: 'text',
                placeholderValue: 'お店の名前',
                highlights: {
                    left: 0, right: 1,
                    top: 0.8, bottom: 1,
                    color: '#00ff00'
                }
            },
            {
                questionId: 2,
                paths: '[1].assetId',
                promptText: '',
                type: 'image',
                placeholderValue: null,
                noAsset: false,
                highlights: {
                    left: 0, right: 1,
                    top: 0.21, bottom: 0.8,
                    color: '#0000ff'
                },
            },
            // {
            //     questionId: 2,
            //     paths: ['[2].color', '[5].color'],
            //     promptText: 'テキストの背景色',
            //     type: 'color',
            //     placeholderValue: '#00ff00',
            //     highlights: [
            //         {
            //             left: 0, right: 1,
            //             top: 0, bottom: 0.21,
            //             color: '#00ff00'
            //         },
            //         {
            //             left: 0, right: 1,
            //             top: 0.8, bottom: 1,
            //             color: '#00ff00'
            //         }
            //     ],
            // },
        ],
        colorAreas: [
            {
                colorId: 0,
                paths: ['[2].color', '[5].color'],
                promptText: '店の名前',
                highlights: [
                    {
                        left: 0, right: 1,
                        top: 0, bottom: 0.21
                    },
                    {
                        left: 0, right: 1,
                        top: 0.8, bottom: 1
                    }
                ],
            },
            {
                colorId: 1,
                paths: '[4].color',
                noBackgroundPicturePaths: '[0].color',
                promptText: '店の名前',
                highlights: {
                    left: 0, right: 1,
                    top: 0.21, bottom: 0.8,
                }
            }
        ],
        backgroundPictureElementIndex: 1,
    },
};