/*global angular*/
'use strict';

var globalElement = require('./global-element');
var textElement = require('./text-element');
var imageElement = require('./image-element');
var videoElement = require('./video-element');
var backgroundElement = require('./background-element');
var phoneNumberElement = require('./phone-number-element');

angular.module('bitcraft-element-library', ['bitcraft-color-picker', 'bitcraft-effect-library']);
angular.module('bitcraft-element-library').component('globalElement', globalElement);
angular.module('bitcraft-element-library').component('textElement', textElement);
angular.module('bitcraft-element-library').component('imageElement', imageElement);
angular.module('bitcraft-element-library').component('videoElement', videoElement);
angular.module('bitcraft-element-library').component('backgroundElement', backgroundElement);
angular.module('bitcraft-element-library').component('phoneNumberElement', phoneNumberElement);
