/*global angular*/
'use strict';

//noinspection JSUnusedGlobalSymbols
angular.module('core').
    directive('repeatOnLastEvent', function () {
        return {
            restrict: 'A',

            link: function (scope, elem, attrs) {
                if (scope.$last) {
                    if (attrs.repeatOnLastEvent) {
                        scope.$eval(attrs.repeatOnLastEvent)();
                    }
                }
            }
        };
    });
