/*global angular, $*/
'use strict';

module.exports = {
    templateUrl: './js/db-edition/delete-user/delete-user.template.html',
    controller: [
        '$element', '$state', '$rootScope', '$translate',
        function ($element, $state, $rootScope, $translate) {
            var self = this;

            self.go = function () {
                // Cf "hack en mousse" in go-to-last-created component.
                var id = $element[0].parentElement.getRowData().row._id;

                var deletionConfirmed = window.confirm($translate.instant('USER_DELETION.USER_CONFIRM_DELETION', { username: id }));

                if (deletionConfirmed) {
                    $state.go('root.delete-user', { username: id });
                }
            };
        }
    ]
};
