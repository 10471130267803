/*global angular, $, JSEvents*/
'use strict';

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 */
function clampValue(value, min, max) {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

/**
 *
 * @param {object} object
 */
function deleteNullValues(object) {
    /** @type {Array.<string>} */
    var propertyNames = Object.getOwnPropertyNames(object);
    /** @type {number} */
    var i;
    /** @type {any} */
    var value;

    if (Array.isArray(object)) {
        for (i = 0; i < object.length; i += 1) {
            value = object[i];

            if (typeof (value) === 'object') {
                deleteNullValues(value)
            }
        }

        return;
    }

    for (i = 0; i < propertyNames.length; i += 1) {
        var propertyName = propertyNames[i];
        value = object[propertyName];

        if (value === null) {
            delete object[propertyName];
        } else if (typeof (value) === 'object') {
            deleteNullValues(value);
        }
    }
}

module.exports = {
    templateUrl: './js/create-video/create-video.template.html',
    controller: [
        '$rootScope', '$scope', '$state', '$stateParams', '$translate', 'UnityWebGL', 'RestHttp', 'Notification', 'GoogleTranslate',
        function ($rootScope, $scope, $state, $stateParams, $translate, UnityWebGL, RestHttp, Notification, GoogleTranslate) {
            /** @type{CreateVideoComponent} */
            var self = this;

            self.elementControllers = [];
            self.unityReady = false;
            self.templatePaused = false;
            self.processingVideo = false;

            self.standardPalette = [
                '#000000', '#003489', '#0100B0', '#4501A2', '#7600AF', '#90017B', '#A80043', '#AC0100',
                '#363636', '#0172F5', '#0000FF', '#8201F7', '#A901D4', '#C101C4', '#FF0066', '#FF0000',
                '#B0B0B0', '#3DC3FE', '#5E72FE', '#AA8EFD', '#CD94FA', '#FA7BFE', '#FE76BB', '#FE7662',
                '#FFFFFF', '#C0EDFE', '#C8D5FF', '#E1D7FE', '#EED6FE', '#FFD2FE', '#FED3E4', '#FFCCC6',
                '#912300', '#6C5100', '#3E5B01', '#274F00', '#015001', '#014B2C', '#004649', '#064A6A',
                '#FF6400', '#CFB900', '#7DAA00', '#4D9B01', '#009B1D', '#009B5B', '#009286', '#0384B9',
                '#FFA000', '#FFFF00', '#B4FF00', '#8BE300', '#4EE444', '#00EB95', '#01F0E0', '#00D8FF',
                '#FFE6BC', '#FFFFC5', '#ECFFAA', '#DFFFA6', '#C7FDC6', '#B7FFD9', '#B8FEF6', '#B3F4FF'
            ];

            /** */
            var generateTemplateFile = function () {
                /** @type{ConfigurationData} */
                var res = {
                    format: self.format,
                    template: $stateParams.template,
                    description: $stateParams.description,
                    elements: []
                };
                var item;

                Object.getOwnPropertyNames(self.controls).forEach(function (key) {
                    item = self.controls[key];

                    if (item.type === 'global') {
                        res.global = item;
                        res.elements.unshift(res.global);
                    } else {
                        res.elements.push(item);
                    }
                });

                // In case some object still have some angular properties on them (leading '$$' in the property name),
                // we let the angular.toJson parse them away from our object
                res = JSON.parse(angular.toJson(res));

                // We then remove other values that only make sense for the UI
                // and do not have any impact on the video rendering itself
                res.elements.forEach(function (element) {
                    delete element.name;
                    delete element.highlighted;
                    delete element.unfolded;

                    if (Array.isArray(element.effects)) {
                        element.effects.forEach(function (effect) {
                            delete effect.unfolded;
                        });
                    }
                });

                return res;
            };

            /**
             * @param file
             * @param {function(Error|undefined, string=)} cb
             */
            var sendAsset = function (file, cb) {
                var xhr = new XMLHttpRequest();

                xhr.open('POST', './rest/uploadAsset', true);

                xhr.timeout = 15 * 60 * 1000; // 15 minutes
                xhr.onload = function () {
                    if (xhr.readyState !== 4) {
                        cb(new Error('Failed to send asset'));
                        return;
                    }

                    cb(undefined, xhr.responseText);
                };

                /** @type{FormData} */
                var formData = new FormData();
                formData.append('files', file, file.name);
                xhr.send(formData);
            };

            /**
             * @param {Array<number>} indexes
             * @param {number} key
             * @returns {boolean}
             */
            var isHighlighted = function (indexes, key) {
                return indexes.some(function (e) { return key === e; });
            };

            /**
             * @param {Array<number>} indexes
             */
            var onHighlight = function (indexes) {
                var key;

                for (key in self.controls) {
                    if (self.controls.hasOwnProperty(key)) {
                        self.controls[key].highlighted = isHighlighted(indexes, parseInt(key, 10));
                    }
                }

                $scope.$applyAsync();
            };

            /**
             * @param {{missing: boolean}} control item in the menu
             */
            function triggerMissingAnimation(control) {
                control.missing = true;
                setTimeout(function () {control.missing = false; }, 1000);
            }

            var onLastFrameNumber = function (lastFrameNumber) {
                self.lastFrameNumber = lastFrameNumber;
            };

            function ensureBackgroundColorsHaveTransparency(controls) {
                const elements = Object.values(controls);

                elements.forEach(c => {
                    if (c.type === 'color' && c.color && c.color.length == 7) {
                        c.color += 'ff';
                    }
                });
            }

            /**
             * @param {VideoDescription} oldTemplate
             */
            function initializeTemplate(oldTemplate) {
                /** @type{number} */
                var i;
                /** @type{string} */
                var index;

                if (oldTemplate) {
                    if (Array.isArray(oldTemplate.elements) && oldTemplate.elements.length > 0) {
                        for (i = 0; i < oldTemplate.elements.length; i += 1) {
                            index = oldTemplate.elements[i].index;
                            Object.assign(self.controls[index], oldTemplate.elements[i]);
                            self.elementControllers[index].updateElement(true);
                        }
                    } else {
                        // To ensure backward compatibility with older templates, we iterate over the arrays
                        // where elements were stored separately before
                        ['texts', 'backgrounds', 'images'].forEach(function (elementType) {
                            for (i = 0; i < oldTemplate[elementType].length; i += 1) {
                                index = oldTemplate[elementType][i].index;
                                Object.assign(self.controls[index], oldTemplate[elementType][i]);
                                self.elementControllers[index].updateElement(true);
                            }
                        });

                        if (oldTemplate.global) {
                            Object.assign(self.controls[oldTemplate.global.index], oldTemplate.global);
                            self.elementControllers[oldTemplate.global.index].updateElement();
                        }
                    }
                } else {
                    Object.getOwnPropertyNames(self.controls).forEach(function (controlIndex) {
                        self.elementControllers[controlIndex].updateElement(true);
                    });
                }

                ensureBackgroundColorsHaveTransparency(self.controls);

                self.savedControls = angular.copy(self.controls);
            }

            this.$onInit = function () {
                if (!$stateParams.description) {
                    $state.go('root.list.templates');
                    return;
                }

                $('.overlay').css('width', 'calc(100% - 30px)');
                $('#canvas').appendTo('.template-wrap');
                UnityWebGL.registerListenHighlights(onHighlight);
                UnityWebGL.registerLastFrameNumber(onLastFrameNumber);

                /** */
                var onTemplateReady = function (config) {
                    /** @type {number} */
                    var controlsLength;
                    self.controls = JSON.parse(config);
                    controlsLength = Object.getOwnPropertyNames(self.controls).length;
                    UnityWebGL.unregisterTemplateReady(onTemplateReady);

                    var checkForControllersInterval = setInterval(
                        function () {
                            if (self.elementControllers.length !== controlsLength) {
                                $scope.$applyAsync();
                                return;
                            }

                            clearInterval(checkForControllersInterval);

                            self.elementControllers.sort(function (a, b) {
                                return a.value.index - b.value.index;
                            });

                            initializeTemplate($stateParams.oldTemplate);

                            var canvas = document.getElementById('canvas');
                            if (self.controls[0].videoWidth !== undefined) {
                                canvas.width = self.controls[0].videoWidth;
                            }
                            if (self.controls[0].videoHeight !== undefined) {
                                canvas.height = self.controls[0].videoHeight;
                            }

                            $('.overlay').css('height', 0);

                            $scope.$applyAsync(); // dunno sometimes angular does not work...
                        }, 200
                    );
                };
                UnityWebGL.registerTemplateReady(onTemplateReady);

                /**
                 * First-time initialization after Unity finished loading itself
                 */
                var onUnityReady = function () {
                    self.unityReady = true;
                    self.templatePaused = false;

                    // Setting up the options defined in TemplateHelpers.GetEnumConfig()
                    var options = UnityWebGL.getOptions();

                    Object.getOwnPropertyNames(options).forEach(function (propertyName) {
                        self[propertyName] = options[propertyName];
                    });

                    var canvas = document.getElementById('canvas');
                    canvas.width = $stateParams.description.size.width;
                    canvas.height = $stateParams.description.size.height;

                    UnityWebGL.sendChangeTemplate($stateParams.template);
                };
                UnityWebGL.onUnityReady(onUnityReady); // wait for unity to finish loading....

                // load unity
                if (!$rootScope.unityLoaded) {
                    var script = document.createElement('script');
                    script.setAttribute('src', './Unity/dev_2022_03_24/UnityLoader.js');
                    document.head.appendChild(script);
                    $rootScope.unityLoaded = true;
                }

                /**
                 * Update the current languageKey and
                 */
                function onTranslationChange() {
                    self.languageKey = $translate.use();
                    GoogleTranslate.getLanguageList(self.languageKey).then(function (data) {
                        self.languageList = [{code: '', name: ''}].concat(data);
                    }, function (err) {
                        console.error(err);
                    });
                }

                // translation
                $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
                    onTranslationChange();
                });
                onTranslationChange();

                // zoom
                self.zoomLevel = 3;
                self.updateZoom(0, self.zoomLevel);
            };

            this.$onDestroy = function () {
                var overlay = $('.overlay');
                overlay.css('width', '0');
                overlay.css('height', '100%');

                $('#canvas').appendTo('#hidden');
                UnityWebGL.unregisterListenHighlights(onHighlight);
                UnityWebGL.unregisterLastFrameNumber(onLastFrameNumber);

                self.sendCommand('SceneStop');
                self.updateZoom(self.zoomLevel, 0);
            };

            self.log = function (param) {
                console.log(param, self.languageList);
            };

            /**
             * @param {string} command
             */
            self.sendCommand = function (command) {
                if (!self.unityReady) { return; }

                if (command === 'SceneReset') {
                    //noinspection JSCheckFunctionSignatures
                    var confirmed = window.confirm($translate.instant('CREATE_VIDEO.CONFIRM_RESET'));
                    if (confirmed) {
                        self.controls = angular.copy(self.savedControls);
                    } else {
                        return;
                    }
                }

                if (command === 'ScenePause') {
                    if (self.templatePaused) {
                        command = 'ScenePlay';
                    }
                    self.templatePaused = !self.templatePaused;
                } else { // SceneReplay
                    if (self.templatePaused) {
                        var playBtn = $('#createVideoPlayBtn');
                        UnityWebGL.sendCommand('ScenePlay');
                        playBtn.removeClass('active');
                        playBtn.attr('aria-pressed', 'false');
                        self.templatePaused = false;
                    }
                }

                UnityWebGL.sendCommand(command);
            };

            /**
             * @param {string} key
             * @param {string} selectedLanguageCode
             */
            self.translate = function (key, selectedLanguageCode) {
                GoogleTranslate.translate(self.languageKey, selectedLanguageCode, self.controls[key].inputText).then(function (data) {
                    self.controls[key].displayText = data;
                    self.controls[key].inputText = data;
                    self.elementControllers[key].updateElement();
                    self.languageKey = selectedLanguageCode;
                }, function (err) {
                    console.error(err);
                });
            };

            /**
             * @param {string} type
             * @param {string} [name]
             */
            function getErrorText(type, name) {
                //noinspection JSCheckFunctionSignatures
                return $translate.instant('ERRORS.' + type.toUpperCase(), {name: $translate.instant(name)}) + '\n';
            }

            /**
             *
             * @param {Array.<object>} itemsWithAsset
             * @param {function} callback
             */
            function postAsset(itemsWithAsset, callback) {
                var item;

                if (itemsWithAsset.length === 0) {
                    callback();
                    return;
                }

                item = itemsWithAsset[0];

                sendAsset(item.file, function (err, id) {
                    if (err) {
                        console.error(err);
                        callback(err);
                        return;
                    }

                    item.assetId = id;
                    delete item.file;
                    delete item.url;
                    itemsWithAsset.shift();

                    postAsset(itemsWithAsset, callback);
                });
            }

            self.postAssets = function (callback) {
                var itemsWithAsset = Object.getOwnPropertyNames(this.controls)
                    .map(function (key) {
                        return self.controls[key];
                    })
                    .filter(function (item) {
                        if (item.type !== 'image' && item.type !== 'video') {
                            return false;
                        }

                        if (item.assetId !== undefined) {
                            delete item.file;
                            delete item.url;
                            return false;
                        }

                        return !item.noAsset;
                    });

                postAsset(itemsWithAsset, callback);
            };

            self.generateVideo = function () {
                var index;
                var failed = false;
                /** @type{string} */
                var errors = '';

                if (self.processingVideo) {
                    return;
                }
                self.processingVideo = true;

                // TODO: delegate the validation behaviour to the components themselves
                for (index in self.controls) {
                    if (self.controls.hasOwnProperty(index)) {
                        var control = self.controls[index];

                        if ((control.type === 'image' || control.type === 'video') && !(control.file || control.assetId) && !control.noAsset) {
                            if (!control.missing) {
                                triggerMissingAnimation(control);
                            }
                            failed = true;
                            errors += getErrorText(control.type, control.name);
                        }

                        if (control.type === 'global') {
                            if (control.videoWidth % 2 === 1) {
                                failed = true;
                                errors += getErrorText('ODD_DIMENSION', $translate.instant('CREATE_VIDEO.WIDTH'));
                            }
                            if (control.videoHeight % 2 === 1) {
                                failed = true;
                                errors += getErrorText('ODD_DIMENSION', $translate.instant('CREATE_VIDEO.HEIGHT'));
                            }
                        }
                    }
                }

                if (!self.format) {
                    triggerMissingAnimation(self);
                    failed = true;
                    errors += getErrorText('format');
                }

                if (!failed) {
                    if (!self.unityReady) {
                        self.processingVideo = false;
                        return;
                    }
                    self.postAssets(function (err) {
                        if (err) {
                            self.processingVideo = false;
                            Notification.error(err.message);
                        } else {
                            RestHttp.restPost('processVideo', {template: generateTemplateFile()}).then(
                                /**
                                 * @param {{id: string, filename: string, ext: string}} resp
                                 */
                                function (resp) {
                                    self.processingVideo = false;
                                    $state.go('root.wait-video', {
                                        id: resp.id,
                                        filename: resp.filename,
                                        ext: resp.ext,
                                        lastFrameNumber: self.lastFrameNumber
                                    });
                                },
                                function (err) {
                                    self.processingVideo = false;
                                    console.error(err);
                                }
                            );
                        }
                    });

                } else {
                    self.processingVideo = false;
                    Notification.error(errors.replace(/\n/g, '<br>'));
                }
            };

            /**
             * @param {number} oldValue
             * @param {number} newValue
             */
            self.updateZoom = function (oldValue, newValue) {
                var canvas = $('#canvas');
                /** @type{number} */
                var newWidth;

                // update zoom
                if (oldValue) {
                    canvas.removeClass('unity-build-zoom-' + oldValue);
                }

                if (newValue) {
                    newValue = clampValue(newValue, 0.5, 6);
                    canvas.addClass('unity-build-zoom-' + newValue);
                    self.zoomLevel = newValue;

                    newWidth = $stateParams.description.size.width * newValue;
                    newWidth = Math.max(newWidth, 507 - 75);

                    // update column size
                    $('#left-column').css('width', (newWidth + 75) + 'px');
                    $('#right-column').css('margin-left', (newWidth + 100) + 'px');
                }
            };

            self.zoomOut = function () {
                self.updateZoom(self.zoomLevel, self.zoomLevel === 1 ? 0.5 : self.zoomLevel - 1);
            };

            self.zoomIn = function () {
                self.updateZoom(self.zoomLevel, self.zoomLevel === 0.5 ? 1 : self.zoomLevel + 1);
            };

            self.getZoomLevelText = function () {
                return 100 * self.zoomLevel + '%';
            };

            self.resetScene = function () {
                var controlsLength = self.controls.length;

                this.sendCommand('SceneReset');

                var checkForControllersInterval = setInterval(
                    function () {
                        if (self.controls.length < controlsLength) {
                            return;
                        }

                        clearInterval(checkForControllersInterval);

                        initializeTemplate($stateParams.oldTemplate);
                    }
                    , 200);
            };

            /**
             * @param {number} scale
             */
            function getScaledResolution(scale) {
                var nativeWidth = $stateParams.description.size.width;
                var nativeHeight = $stateParams.description.size.height;

                if (self.controls && self.controls[0]) {
                    nativeWidth = self.controls[0].videoWidth || nativeWidth;
                    nativeHeight = self.controls[0].videoHeight || nativeHeight;
                }

                return scale * nativeWidth + 'x' + scale * nativeHeight;
            }

            self.getNativeResolution = function () {
                return getScaledResolution(1);
            };

            self.getZoomedResolution = function () {
                return getScaledResolution(self.zoomLevel);
            };

            self.getTemplateDisplayName = function () {
                return $stateParams.description.displayName + ($rootScope.groups.indexOf('admin') !== -1 && !!$stateParams.oldTemplate ? '(' + $stateParams.oldTemplate.description.displayName + ')' : '');
            };

            self.updateImages = function () {
                self.elementControllers.forEach(function (elementController) {
                    if (elementController.value && elementController.value.type === 'image') {
                        // Without the setTimeout, sending two updates to Unity in the same
                        // JS tick would prevent the images from being updated
                        setTimeout(function () {
                            elementController.sendElementToUnity();
                        }, 0);
                    }
                });
            };
        }
    ]
};

/**
 * @typedef {Object} CreateVideoComponent
 *
 * Misc functions
 * @property {function(string)} log
 * @property {function(string)} sendCommand
 * @property {function(LanguageItem)} isPriorityLanguage
 * @property {function(string, LanguageItem)} selectAndTranslate
 * @property {function(string)} translate
 * @property {function(string)} updateElement
 * @property {function(string)} getEffectName
 *
 * @property {function()} generateVideo
 *
 * Unity communication
 * @property {boolean} unityReady
 * @property {boolean} templatePaused
 *
 * Controls
 * @property {Array.<Control>} controls
 * @property {Array.<Control>} savedControls
 * @property {string} format
 * @property {boolean|undefined} missing
 *
 * Configuration
 * @property {Array<string>} fonts
 * @property {Array<KeyValue>} horizontalFits
 * @property {Array<KeyValue>} verticalFits
 * @property {Array<KeyValue>} imageFits
 * @property {Array<KeyValue>} videoFits
 * @property {Array<KeyValue>} scrollDirections
 *
 * Translation related
 * @property {string} languageKey
 * @property {Array<LanguageItem>} languageList
 *
 * Zoom
 * @property {number} zoomLevel
 * @property {function(number, number)} updateZoom
 * @property {function()} zoomIn
 * @property {function()} zoomOut
 *
 * Misc
 * @property {number} lastFrameNumber
 */

/**
 * @typedef {Object} LanguageItem
 * @property {string} code
 * @property {string} name
 */

/**
 * @typedef {Object} Control
 * @property {string} name
 * @property {string} inputText
 * @property {string} displayText
 * @property {string} font
 * @property {string} size
 * @property {string} color
 * @property {string} outlineSize
 * @property {string} outlineColor
 * @property {string} softness
 * @property {string} outlineSize
 * @property {number} characterSpacing
 * @property {number} lineSpacing
 * @property {string} bold
 * @property {string} horizontalFit
 * @property {string} verticalFit
 * @property {boolean|undefined} missing
 * @property {number|undefined} slider
 */
