'use strict';

var BaseElement = require('../baseElement');

/** */
var TextElementController = function ($scope, $stateParams, UnityWebGL) {
    BaseElement.apply(this, [$scope, UnityWebGL]);

    this.$scope = $scope;
    this.$stateParams = $stateParams;

    this.sizeProxy = function (size) {
        var applyDisplayRatio = (this.$stateParams.template.indexOf('_canvas') !== -1);

        if (arguments.length === 1) {
            if (!this.value) {
                return;
            }

            this.value.size = size * (applyDisplayRatio ? 10 : 1);

            return;
        }

        return Math.floor((this.value ? this.value.size : 0) / (applyDisplayRatio ? 10 : 1));
    };
};

TextElementController.prototype = Object.create(BaseElement.prototype);
TextElementController.constructor = TextElementController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-element-library/text-element/textElement.template.html',
    bindings: {
        'key': '<',
        'value': '<',
        'createVideoController': '<',
        'verticalText': '<',
        'standardPalette': '<'
    },

    controller: ['$scope', '$stateParams', 'UnityWebGL', TextElementController]
};

TextElementController.prototype.$onInit = function () {
    var i;
    //this.updateText(this.key);
    this.selectedLanguageCode = this.createVideoController.languageList[0].code;

    this.sizeProxy = this.sizeProxy;
};

/**
 * @param {string} key
 * Kept in the createVideoController because the text needs
 * to be initialized as soon as the template is loaded
 */
TextElementController.prototype.updateText = function (updateEffects, additionalCommand) {
    this.value.displayText = this.value.inputText;
    this.value.dilate = this.value.outlineSize;

    this.sendElementToUnity(updateEffects, additionalCommand);

    if (this.$stateParams.template.indexOf('_canvas') !== -1) {
        this.createVideoController.updateImages();
    }
};

TextElementController.prototype.updateElement = function (updateEffects, additionalCommand) {
    this.updateText(updateEffects, additionalCommand);
};

/**
 * @param {LanguageItem} language
 */
TextElementController.prototype.isPriorityLanguage = function (language) {
    return language.code === 'en' ||
        language.code === 'ko' ||
        language.code === 'zh' ||
        language.code === 'ja';
};

/**
 * @param {string} key
 * @param {LanguageItem} language
 */
TextElementController.prototype.selectAndTranslate = function (language) {
    this.selectedLanguageCode = language.code;
    this.translate();
};

/**
 * @param {string} key
 * Kept in the createVideoController because the actual code
 * requires access to the GoogleTranslate object
 */
TextElementController.prototype.translate = function () {
    if (this.selectedLanguageCode !== '') {
        this.createVideoController.translate(this.key, this.selectedLanguageCode);
    }
};

TextElementController.prototype.getFittingSuffix = function () {
    return (this.verticalText ? '_FOR_VERTICAL_TEXT' : '');
}
