'use strict';

var BaseEffect = require('./baseEffect');

var ScrollingEffectController = function ($scope) {
    BaseEffect.apply(this, [$scope]);

    this.$scope = $scope;
};

ScrollingEffectController.prototype = Object.create(BaseEffect.prototype);
ScrollingEffectController.constructor = ScrollingEffectController;

module.exports = {
    templateUrl: './js/create-video/bitcraft-effect-library/scrollingEffect.template.html',
    bindings: {
        'elementIndex': '<',
        'effectIndex': '<',
        'value': '<',
        'createVideoController': '<'
    },

    controller: ['$scope', ScrollingEffectController]
};

ScrollingEffectController.prototype.$onInit = function () {};

ScrollingEffectController.prototype.updateScrollingEffect = function () {
    this.parentElementController.updateElement(undefined, 'SceneReplay');
};

ScrollingEffectController.prototype.updateEffect = function () {
    this.updateScrollingEffect();
};

ScrollingEffectController.prototype.getScrollingDirectionClass = function () {
    if (this.value.horizontalAxis) {
        if (this.value.reverseDirection) {
            return 'fa-arrow-right';
        }

        return 'fa-arrow-left';
    }

    if (this.value.reverseDirection) {
        return 'fa-arrow-up';
    }

    return 'fa-arrow-down';
};
