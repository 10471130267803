/*global angular, $, JSEvents*/
'use strict';

const baseTemplateQuestionData = require('./baseTemplateQuestionData.js');
const COLOR_PALETTES = require('./colorPalettes.js');
const HIDDEN_CLASSNAME = 'hidden-element';
// `display: none;` prevents items from counting as present inside a CSS grid, so we make those invisible instead
const INVISIBLE_CLASSNAME = 'invisible-element';
const LOADING_OVERLAY_ACTIVE_CLASSNAME = 'active';
const MINIMUM_STEP_WAIT_TIME_IN_MS = 750;
const BASE_TEMPLATE_TAG = 'ベーステンプレート';
const INDUSTRIES = [ 'RESTAURANT', 'AUTOMOBILE', 'OSTHEOPATHY', 'HAIRDRESSER', 'REAL_ESTATE', 'CRAM_SCHOOL', 'OTHER' ];

const INDUSTRIES_NAME = {
    'RESTAURANT': '飲食店',
    'AUTOMOBILE': '車関連',
    'OSTHEOPATHY': '鍼灸整骨院',
    'HAIRDRESSER': '美容室・散髪屋',
    'REAL_ESTATE': '不動産',
    'CRAM_SCHOOL': '学習塾',
    'OTHER': 'その他',
};

const INDUSTRIES_TEXT_PLACEHOLDER = {
    'RESTAURANT': ['予約受付開始！！', '大盛無料', 'ランチの飲料無料'],
    'AUTOMOBILE': ['車関連', '車庫'],
    'OSTHEOPATHY': ['鍼灸師'],
    'HAIRDRESSER': ['理容師', '美容師', '髪結い'],
    'REAL_ESTATE': ['不動産屋', 'マンション', 'アパート'],
    'CRAM_SCHOOL': ['放課後', '学校＋'],
    'OTHER': ['-50% !', 'GREAT!', 'BEST!', 'ONLY HERE'],
};

const INDUSTRIES_LONG_TEXT_PLACEHOLDER = {
    'RESTAURANT': ['今すぐご予約で入会金無料！この機会をお見逃しなく！', 'ウイルスなんて吹っ飛ばせ！たくさん食べて免疫向上！', 'ランチ時のみ1杯無料サービスさせて頂きます！'],
    'AUTOMOBILE': ['車関連', '車庫'],
    'OSTHEOPATHY': ['鍼灸師'],
    'HAIRDRESSER': ['理容師', '美容師', '髪結い'],
    'REAL_ESTATE': ['不動産屋', 'マンション', 'アパート'],
    'CRAM_SCHOOL': ['放課後', '学校＋'],
    'OTHER': ['-50% !', 'GREAT!', 'BEST!', 'ONLY HERE'],
};

function getIndustryPlaceholder(industry, isLongText) {
    const placeholders = (isLongText ? INDUSTRIES_LONG_TEXT_PLACEHOLDER[industry] : INDUSTRIES_TEXT_PLACEHOLDER[industry]);

    if (Array.isArray(placeholders)) {
        return placeholders[Math.floor(Math.random() * placeholders.length)];
    }

    return;
}

const monthlyTags = {
    0: ['正月', '新年会', '年始'],
    5: ['夏', '雨',]
};

async function waitForMilliseconds(waitingTime) {
    await new Promise(resolve => setTimeout(resolve, waitingTime));
};

async function loadTemplates(component) {
    if (Array.isArray(component.dataStore.templates) && component.dataStore.templates.length > 0) {
        return;
    }

    if (!component || !component.dataStore) {
        console.error('Malformed component or dataStore', component);
        return;
    }

    var templates = [];

    var templatesPromise = new Promise((resolve, reject) => {
        component.RestHttp.restGet('listTemplates').then(function (data) {
            component.dataStore.templates = data.filter(template => template.templateData.tags.indexOf(BASE_TEMPLATE_TAG) === -1);

            resolve();
        }, reject);
    });

    await templatesPromise;

    await component.updateAvailableTags();
};

async function promisifyRestGet(RestHttp, url) {
    return await new Promise((resolve, reject) => {
        RestHttp.restGet(url).then(resolve, reject);
    });
}

async function sendAsset(file) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        xhr.open('POST', './rest/uploadAsset', true);

        xhr.timeout = 15 * 60 * 1000; // 15 minutes
        xhr.onload = function () {
            if (xhr.readyState !== 4) {
                reject(new Error('Failed to send asset'));
                return;
            }

            resolve(xhr.responseText);
        };

        /** @type{FormData} */
        var formData = new FormData();
        formData.append('files', file, file.name);
        xhr.send(formData);
    });
};

function applyQuestionData(configuration, questionData) {
    const backgroundPicturePath = `[${questionData.backgroundPictureElementIndex}].assetId`;

    const hasBackgroundPicture = questionData.questions.some(question => {
        return question.type === 'image' && !question.noAsset &&
            (
                Array.isArray(question.paths) ?
                question.paths.some(path => path === backgroundPicturePath) :
                question.paths === backgroundPicturePath
            )
        ;
    });

    console.log(`\n\nhasBackgroundPicture: ${hasBackgroundPicture}\n\n`);

    for (const question of questionData.questions) {
        if (question.value === undefined && question.noAsset) {
            continue;
        }

        let relevantPathArray = (hasBackgroundPicture ? undefined : question.noBackgroundPicturePaths) || question.paths;

        if (Array.isArray(relevantPathArray) === false) {
            relevantPathArray = [relevantPathArray];
        }

        const value = question.value;
        const noAssetValue = question.noAsset;

        if ((value === undefined || value === null) && !noAssetValue) {
            continue;
        }

        for (const path of relevantPathArray) {
            eval('configuration.elements' + path + ' = value;');

            if (path.lastIndexOf('assetId') === (path.length - 'assetId'.length)) {
                eval('configuration.elements' + path.replace('assetId', 'noAsset') + ' = noAssetValue;');
            }
        }
    }

    return configuration;
}

function addHighlightToElement(highlightData, hightlightContainerElement, color = null) {
    var highlight = document.createElement('div');
    highlight.classList.add('highlight');

    hightlightContainerElement.appendChild(highlight);

    highlight.style.top = `${100 * highlightData.top}%`;
    highlight.style.right = `${100 * (1 - highlightData.right)}%`;
    highlight.style.bottom = `${100 * (1 - highlightData.bottom)}%`;
    highlight.style.left = `${100 * highlightData.left}%`;
    highlight.style.backgroundColor = color || highlightData.color;
}

function convertOldConfiguration(configuration) {
    if (!configuration.elements) {
        configuration.elements = [
            {...configuration.global, type: 'global', videoWidth: configuration.description.size.width, videoHeight: configuration.description.size.height},
            ...configuration.images.map(image => ({ ...image, type: 'image' })),
            ...configuration.texts.map(text => ({ ...text, type: ((text.effects || []).length > 0 ? 'slidingText' : 'text') })),
            ...configuration.backgrounds.map(background => ({ ...background, type: 'color' })),
            ...configuration.videos.map(video => ({ ...video, type: 'video' }))
        ].sort((a, b) => (a.index < b.index ? -1 : 1));
    }

    configuration.global = configuration.elements.filter(element => element.type === 'global')[0];

    return configuration;
}

function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

var AiCreateVideoDataStore = {
    tags: [],
    selectedSize: { width: 0, height: 0 },
    selectedTemplate: null,
    selectedIndustry: null,
    selectedPalette: COLOR_PALETTES[0],

    questionData: deepCopy(baseTemplateQuestionData.Template02),

    templates: [],
    sizes: [],
    colors: [],

    getRelevantTags: function () {
        return (INDUSTRIES_NAME[this.selectedIndustry] !== undefined ? INDUSTRIES_NAME[this.selectedIndustry] : []).concat(this.tags);
    },

    getColorHighlights: function (palette) {
        return this.questionData.colorAreas.reduce((acc, val, i) => {
            acc.push(...(Array.isArray(val.highlights) ? val.highlights : [val.highlights]).map(highlight => ({ ...highlight, color: palette[(val.colorId ? val.colorId : i) % palette.length] })));
            return acc;
        }, []);
    },
};

var IndustrySelectionStep = {
    load: async (component) => {
        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.INDUSTRY_SELECTION_INSTRUCTIONS', {username: component.$rootScope.username})
        component.setStepControls(component.industrySelectorControlsElement);
        component.stepSpecificButtonElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.VALIDATE');

        await loadTemplates(component);
    },
};

var TagSelectionStep = {
    load: async (component) => {
        if (!this.tagsUpdatedCallback) {
            this.tagsUpdatedCallback = () => component.refresh();
            component.$scope.$watch('$ctrl.dataStore.tags', this.tagsUpdatedCallback, true);
        }

        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.TAG_INPUT_INSTRUCTIONS', {username: component.$rootScope.username})
        component.setStepControls(component.tagInputControlsElement);
        component.stepSpecificButtonElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.VALIDATE');

        await loadTemplates(component);
    },

    stepSpecificButtonCallback: async (component) => {
        component.loadNextStep();
    },

    isStepSpecificButtonDisabled: (component) => {
        return component.dataStore.tags.length === 0;
    }
};

var SizeSelectionStep = {
    load: async (component) => {
        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.SIZE_SELECTOR_INSTRUCTIONS');
        component.setStepControls(component.sizeSelectorControlsElement);

        await loadTemplates(component);

        const relevantTags = component.dataStore.getRelevantTags();

        var relevantTemplates = component.dataStore.templates.filter(template => template.templateData.tags.some(tag => relevantTags.indexOf(tag) !== -1));

        var alreadyAddedSizes = {};

        component.dataStore.sizes = relevantTemplates
            .reduce((acc, template) => {
                var sizeAsString = `${template.templateData.size.width}X${template.templateData.height}`;

                if (!alreadyAddedSizes[sizeAsString]) {
                    alreadyAddedSizes[sizeAsString] = true;
                    acc.push(template.templateData.size);
                }

                return acc;
            }, [{ width: 144, height: 192 }]);
        component.refresh();
    },
};

var TemplateSelectionStep = {
    load: async (component) => {
        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.TEMPLATE_SELECTOR_INSTRUCTIONS');
        component.setStepControls(component.templateSelectorControlsElement);

        await loadTemplates(component);

        const relevantTags = component.dataStore.getRelevantTags();

        var relevantTemplates = component.dataStore.templates.filter(template => {
            return template.templateData.tags.some(tag => relevantTags.indexOf(tag) !== -1)
                && template.templateData.size.width === component.dataStore.selectedSize.width
                && template.templateData.size.height === component.dataStore.selectedSize.height;
        });

        relevantTemplates.sort((a, b) => (a.templateData.displayName === 'CO00121' ? -1 : (b.templateData.displayName === 'CO00121' ? 1 : 0)));

        component.dataStore.filteredTemplates = relevantTemplates;
        component.refresh();
    },
};

var QuestionStep = {
    load: async (component) => {
        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.QUESTION_INSTRUCTIONS');
        component.setStepControls(component.questionInputControlsElement);

        await loadTemplates(component);

        //var selectedTemplate = component.dataStore.templates.filter(t => t.templateData.displayName === 'CO00002')[0];
        //component.dataStore.selectedTemplate = selectedTemplate;
        var selectedTemplate = component.dataStore.selectedTemplate;
        component.dataStore.questionData = deepCopy(baseTemplateQuestionData[selectedTemplate.templateData.name]);
        component.stepSpecificButtonElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.VALIDATE');

        for (const question of component.dataStore.questionData.questions) {
            question.value = (question.type === 'text' ? getIndustryPlaceholder(component.dataStore.selectedIndustry, question.isLongText) : undefined) || question.placeholderValue;
        }

        const questionInputElement = document.querySelector('.question-input');
        const thumbnailContainerElement = document.querySelector('.template-thumbnail');
        const thumbnailImageElement = document.querySelector('.template-thumbnail img');

        const borderSize = 2 * 2;
        const marginSize = 5;

        thumbnailContainerElement.style.height = (questionInputElement.clientHeight - (marginSize)) + 'px';
        thumbnailImageElement.style.height = (questionInputElement.clientHeight - (borderSize + marginSize)) + 'px';

        component.refresh();

        component.focusQuestion(component.dataStore.questionData.questions[0]);
    },

    stepSpecificButtonCallback: async (component) => {
        component.loadNextStep();
    },

    isStepSpecificButtonDisabled: (component) => false,
};

var ColorStep = {
    load: async (component) => {
        component.stepInstructionsElement.innerHTML = component.$translate.instant('AI_CREATE_VIDEO.COLOR_SELECTOR_INSTRUCTIONS');
        component.setStepControls(component.colorSelectorControlsElement);

        await loadTemplates(component);

        const size = {
            width: 148,
            height: 192,
        };

        component.dataStore.colors = COLOR_PALETTES.map(palette => ({ ...size, palette, colors: component.dataStore.getColorHighlights(palette) }));
        component.refresh();
    },

    stepSpecificButtonCallback: async (component) => {
        const imageQuestions = component.dataStore.questionData.questions.filter(q => q.type === 'image');

        for (const imageQuestion of imageQuestions) {
            if (!imageQuestion.fileBlob) {
                continue;
            }

            const assetUploadResponse = await sendAsset(imageQuestion.fileBlob);

            if (assetUploadResponse.length !== 36 || (assetUploadResponse.match(/-/g) || []).length !== 4) {
                console.error('Error while uploading asset ', imageQuestions.fileBlob, ':', assetUploadResponse);
                return;
            }

            imageQuestion.value = assetUploadResponse;
        }

        const palette = component.dataStore.selectedPalette;
        const questions = component.dataStore.questionData.colorAreas.map((colorArea, i) => ({...colorArea, value: palette[(colorArea.colorId !== undefined ? colorArea.colorId : i) % palette.length]})).concat(component.dataStore.questionData.questions);

        const configuration = applyQuestionData(convertOldConfiguration(deepCopy(component.dataStore.selectedTemplate.configuration)), { ...component.dataStore.questionData, questions });
        delete configuration._id;
        configuration.format = 'wmv';

        configuration.elements.forEach(element => {
            if (element.type === 'text') {
                element.bestFit = true;
            }

            if (element.type === 'image') {
                if (!element.noAsset && !element.assetId) {
                    element.noAsset = true;
                }
            }

            if (element.type === 'color') {
                if (element.color && element.color.length === 7) {
                    element.color += 'ff';
                }
            }
        });

        configuration.global = configuration.elements.filter(element => element.type === 'global')[0];
        configuration.images = configuration.elements.filter(element => element.type === 'image');
        configuration.texts = configuration.elements.filter(element => element.type === 'text' || element.type === 'slidingText');
        configuration.backgrounds = configuration.elements.filter(element => element.type === 'color');
        configuration.videos = configuration.elements.filter(element => element.type === 'video');

        configuration.elements = [
            {...configuration.global, type: 'global', videoWidth: configuration.description.size.width, videoHeight: configuration.description.size.height},
            ...configuration.images.map(image => ({ ...image, type: 'image' })),
            ...configuration.texts.map(text => ({ ...text, type: ((text.effects || []).length > 0 ? 'slidingText' : 'text') })),
            ...configuration.backgrounds.map(background => ({ ...background, type: 'color' })),
            ...configuration.videos.map(video => ({ ...video, type: 'video' }))
        ].sort((a, b) => (a.index < b.index ? -1 : 1));

        await component.toggleLoadingOverlay(true);
        await waitForMilliseconds(1000);

        delete configuration.createdAt;
        await component.exportVideo(configuration);
    },

    isStepSpecificButtonDisabled: (component) => true,
};

var AiCreateVideoComponent = function ($rootScope, $scope, $element, $state, $translate, RestHttp) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$element = $element[0];
    this.$state = $state;
    this.$translate = $translate;
    this.RestHttp = RestHttp;

    this.dataStore = {...AiCreateVideoDataStore};

    this.steps = [ IndustrySelectionStep, TagSelectionStep, SizeSelectionStep, TemplateSelectionStep, QuestionStep, ColorStep ];
    this.currentStepIndex = 0;

    this.dialogContainer = this.$element.querySelector('.dialog-container');
    this.avatarContainerElement = this.$element.querySelector('.avatar-container');
    this.aiAvatarElement = this.avatarContainerElement.querySelector('.ai-avatar');
    this.speechBubbleArrowContainer = this.$element.querySelector('.speech-bubble-arrow-container');

    this.loadingOverlayElement = this.$element.querySelector('.loading-overlay');
    this.stepInstructionsElement = this.$element.querySelector('.step-instructions');
    this.stepControlsElement = this.$element.querySelector('.step-controls');

    this.industrySelectorControlsElement = this.$element.querySelector('.step-controls .industry-selector');

    this.tagInputControlsElement = this.$element.querySelector('.step-controls .tag-input');

    this.sizeSelectorControlsElement = this.$element.querySelector('.step-controls .size-selector');

    this.templateSelectorControlsElement = this.$element.querySelector('.step-controls .template-selector');

    this.questionInputControlsElement = this.$element.querySelector('.step-controls .question-input');

    this.colorSelectorControlsElement = this.$element.querySelector('.step-controls .color-selector');

    this.goBackButtonElement = this.$element.querySelector('.footer .go-back-button');
    this.stepSpecificButtonElement = this.$element.querySelector('.footer .step-specific-button');

    this.availableTags = ['ada', 'mad'];
    this.availableIndustries = INDUSTRIES;
    this.industryNames = INDUSTRIES_NAME;

    this.standardPalette = [
        '#000000', '#003489', '#0100B0', '#4501A2', '#7600AF', '#90017B', '#A80043', '#AC0100',
        '#363636', '#0172F5', '#0000FF', '#8201F7', '#A901D4', '#C101C4', '#FF0066', '#FF0000',
        '#B0B0B0', '#3DC3FE', '#5E72FE', '#AA8EFD', '#CD94FA', '#FA7BFE', '#FE76BB', '#FE7662',
        '#FFFFFF', '#C0EDFE', '#C8D5FF', '#E1D7FE', '#EED6FE', '#FFD2FE', '#FED3E4', '#FFCCC6',
        '#912300', '#6C5100', '#3E5B01', '#274F00', '#015001', '#014B2C', '#004649', '#064A6A',
        '#FF6400', '#CFB900', '#7DAA00', '#4D9B01', '#009B1D', '#009B5B', '#009286', '#0384B9',
        '#FFA000', '#FFFF00', '#B4FF00', '#8BE300', '#4EE444', '#00EB95', '#01F0E0', '#00D8FF',
        '#FFE6BC', '#FFFFC5', '#ECFFAA', '#DFFFA6', '#C7FDC6', '#B7FFD9', '#B8FEF6', '#B3F4FF'
    ];

    this.setStepControls();
    this.toggleLoadingOverlay(true);

    this.chatbotContainerResizeObserver = new ResizeObserver(entries => {
        this.resizeAiAvatar();
    });
    this.chatbotContainerResizeObserver.observe(this.$element.children[1]);
    this.aiAvatarElement.addEventListener('load', () => this.resizeAiAvatar());

    window.t = this;
};

AiCreateVideoComponent.prototype.$onInit = async function () {
    this.loadStep(this.steps[this.currentStepIndex]);
};

AiCreateVideoComponent.prototype.$onDestroy = async function () {
    this.toggleLoadingOverlay(true);
};

AiCreateVideoComponent.prototype.resizeAiAvatar = function () {
    const speechBubbleCurrentWidth = this.speechBubbleArrowContainer.clientWidth;
    const safetyRatio = 1.2;

    const maxHeight = this.$element.children[1].clientHeight - this.dialogContainer.clientHeight;
    const maxWidth = this.$element.children[1].clientWidth - (safetyRatio * speechBubbleCurrentWidth);

    let avatarWidth = this.aiAvatarElement.naturalWidth;
    let avatarHeight = this.aiAvatarElement.naturalHeight;
    let avatarRatio = avatarWidth / avatarHeight;

    avatarHeight = Math.min(avatarHeight, maxHeight);
    avatarWidth = avatarRatio * avatarHeight;

    avatarWidth = Math.min(avatarWidth, maxWidth);
    avatarHeight = avatarWidth / avatarRatio;

    this.aiAvatarElement.width = avatarWidth;
    this.aiAvatarElement.height = avatarHeight;

    this.aiAvatarElement.style.marginLeft = `${0.10 * avatarWidth}px`;
}

AiCreateVideoComponent.prototype.setStepControls = function (stepControlsElement) {
    Array.prototype.forEach.call(this.stepControlsElement.children, stepControl => stepControl.classList.add(HIDDEN_CLASSNAME));

    if (stepControlsElement) {
        stepControlsElement.classList.remove(HIDDEN_CLASSNAME);
    }
};

AiCreateVideoComponent.prototype.updateAvailableTags = async function () {
    if (!this.dataStore) {
        return;
    }

    let availableTags = [];

    // whatever you entered last time (●前回入力された文字), as long as it is a tag that exist in our system I guess
    // TODO: FIXME: retrieve the tags on the user's last video

    // the three "most used" tags from everyone's videos
    const globalTopTags = await promisifyRestGet(this.RestHttp, 'getGlobalTopTags');
    availableTags.push(...globalTopTags);

    // your own three "most used" tags from your videos
    const ownTopTags = await promisifyRestGet(this.RestHttp, 'getOwnTopTags');
    availableTags.push(...ownTopTags);

    // seasonal tags that depend on the current month
    availableTags.push(... (monthlyTags[new Date().getMonth()] || []));

    availableTags = availableTags.filter(tag => this.availableIndustries.some(industry => tag === industry) === false);

    availableTags.unshift('おすすめ');
    this.availableTags = Array.from(new Set(availableTags));
    this.refresh();
}

AiCreateVideoComponent.prototype.selectSize = function (size) {
    this.dataStore.selectedSize = size;
    this.loadNextStep();
}

AiCreateVideoComponent.prototype.selectTemplate = async function (template) {
    if (!template) {
        return;
    }

    if (Object.keys(baseTemplateQuestionData).indexOf(template.templateData.name) === -1) {
        alert('このテンプレートはまだ使えません');
        return;
    }

    this.dataStore.selectedTemplate = template;

    this.loadNextStep();
}

AiCreateVideoComponent.prototype.selectPalette = function (palette) {
    this.dataStore.selectedPalette = palette;
    this.steps[this.currentStepIndex].stepSpecificButtonCallback(this);
}

AiCreateVideoComponent.prototype.toggleLoadingOverlay = async function (value) {
    var currentClasslist = this.loadingOverlayElement.getAttribute('class');

    if (value === undefined) {
        this.loadingOverlayElement.classList.toggle(LOADING_OVERLAY_ACTIVE_CLASSNAME);
    } else  if (value) {
        this.loadingOverlayElement.classList.add(LOADING_OVERLAY_ACTIVE_CLASSNAME);
    } else this.loadingOverlayElement.classList.remove(LOADING_OVERLAY_ACTIVE_CLASSNAME);

    // If a classList change happened we wait for the CSS transition to end
    if (currentClasslist !== this.loadingOverlayElement.getAttribute('class')) {
        await waitForMilliseconds(200);
    }
};

AiCreateVideoComponent.prototype.loadStep = async function(step) {
    var newStepIndex = this.steps.indexOf(step);

    if (newStepIndex === -1) {
        console.error('Step does not belong to the component\'s steps', step);
        return;
    }

    if (this.stepSpecificButtonCallback) {
        this.stepSpecificButtonElement.removeEventListener('click', this.stepSpecificButtonCallback);
        delete this.stepSpecificButtonCallback;
    }

    await this.toggleLoadingOverlay(true);

    this.stepInstructionsElement.innerHTML = '';
    this.setStepControls();

    var timeBeforeLoadingStep = Date.now();

    if (step.load) {
        await step.load.call(step, this);
    } else {
        console.error(`Step has no load function`, step);
    }

    await waitForMilliseconds(MINIMUM_STEP_WAIT_TIME_IN_MS - (Date.now() - timeBeforeLoadingStep));

    if (step.stepSpecificButtonCallback) {
        this.stepSpecificButtonCallback = () => step.stepSpecificButtonCallback.call(step, this);
        this.stepSpecificButtonElement.addEventListener('click', this.stepSpecificButtonCallback);
        this.stepSpecificButtonElement.classList.remove(INVISIBLE_CLASSNAME);
    } else {
        this.stepSpecificButtonElement.classList.add(INVISIBLE_CLASSNAME);
    }

    this.currentStepIndex = newStepIndex;

    if (this.currentStepIndex === 0) {
        this.goBackButtonElement.classList.add(INVISIBLE_CLASSNAME);
    } else {
        this.goBackButtonElement.classList.remove(INVISIBLE_CLASSNAME);
    }

    this.refresh();

    await this.toggleLoadingOverlay(false);
};

AiCreateVideoComponent.prototype.loadNextStep = function () {
    if (this.currentStepIndex + 1 >= this.steps.length) {
        return;
    }

    this.loadStep(this.steps[this.currentStepIndex + 1]);
};

AiCreateVideoComponent.prototype.loadPreviousStep = function () {
    if (this.currentStepIndex - 1 < 0) {
        return;
    }

    this.loadStep(this.steps[this.currentStepIndex - 1]);
};

AiCreateVideoComponent.prototype.refresh = function () {
    if (!this.$rootScope.$$phase) {
        this.$rootScope.$apply();
    }
};

AiCreateVideoComponent.prototype.getSizeStyle = function (size) {
    var width = `width: ${100 * size.width / size.height}%;`;
    var height = 'height: 100%;';
    var marginTop = '';

    if (size.height < size.width) {
        width = 'width: 100%;';
        height = `height: ${100 * size.height / size.width}%;`;
        marginTop = `margin-top: ${100 * (1 - size.height / size.width) / 2}%;`;
    }


    return `${width} ${height} ${marginTop}`
};

AiCreateVideoComponent.prototype.isStepSpecificButtonDisabled = function () {
    if (this.steps[this.currentStepIndex].isStepSpecificButtonDisabled) {
        return this.steps[this.currentStepIndex].isStepSpecificButtonDisabled(this);
    }

    return false;
};

AiCreateVideoComponent.prototype.addTag = function (tag) {
    if (this.dataStore.tags.indexOf(tag) === -1) {
        this.dataStore.tags.push(tag);
        this.refresh();
    }
}

AiCreateVideoComponent.prototype.focusQuestion = function (question) {
    var highlightContainer = document.getElementById('highlight-container');

    for (var child of [...highlightContainer.children]) {
        if (child.nodeName !== 'IMG') {
            highlightContainer.removeChild(child);
        }
    }

    this.dataStore.selectedQuestion = -1;

    if (question) {
        if (Array.isArray(question.highlights) === false) {
            question.highlights = [question.highlights];
        }

        this.dataStore.selectedQuestion = this.dataStore.questionData.questions[question.questionId];

        for (var highlightData of question.highlights) {
            addHighlightToElement(highlightData, highlightContainer);
        }
    }
}

AiCreateVideoComponent.prototype.exportVideo = function (configuration) {
    var self = this;
    self.RestHttp.restPost('processVideo', {template: configuration}).then(
        /**
         * @param {{id: string, filename: string, ext: string}} resp
         */
        function (resp) {
            self.processingVideo = false;
            self.$state.go('root.wait-video', {
                id: resp.id,
                filename: resp.filename,
                ext: resp.ext,
                lastFrameNumber: self.lastFrameNumber
            });
        },
        function (err) {
            self.processingVideo = false;
            console.error(err);
        }
    );
}

AiCreateVideoComponent.prototype.selectIndustry = function (industry) {
    this.dataStore.selectedIndustry = industry;

    // Automatic transtion when selecting an industry
    this.loadNextStep();
}

module.exports = {
    templateUrl: './js/ai-create-video/ai-create-video.template.html',

    bindings: {},

    controller: ['$rootScope', '$scope', '$element', '$state', '$translate', 'RestHttp', AiCreateVideoComponent]
};
