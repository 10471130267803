/*global angular, $*/
'use strict';

module.exports = {
    templateUrl: './js/db-edition/go-to-last-created/go-to-last-created.template.html',
    controller: [
        '$element', '$state', '$rootScope',
        function ($element, $state, $rootScope) {
            var self = this;

            self.go = function () {
                // 2020/02/20 Hack en mousse
                // Get the original object instead of a serialized value.
                // The idea is to retrieve the good data from the original reference instead of
                // the initial data (serialized one).
                var id = $element[0].parentElement.getRowData().row._id;

                if ($rootScope.username === id) {
                    $state.go('root.list.recent');
                } else {
                    $state.go('root.list.videos', {
                        username: id
                    });
                }
            };
        }
    ]
};
