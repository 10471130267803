/*global angular, $*/
'use strict';

//noinspection JSUnusedGlobalSymbols
angular.module('forgotten-password').
    component('forgottenPassword', {
        templateUrl: './js/login-screen/forgotten-password/forgotten-password.template.html',
        controller: [
            '$scope', '$state', 'Notification', 'RestHttp',
            function loginScreenController($scope, $state, Notification, RestHttp) {
                var self = this;

                self.resetPassword = function () {
                    RestHttp.restPost('resetPassword', {
                        username: $scope.username,
                        mail: $scope.mail
                    }).then(function () {
                        $scope.$root.emailForPasswordReset = $scope.mail;
                        $state.go('^');
                    }, function (resp) {
                        if (resp.status === 406) {
                            Notification.error(
                                {message: 'Invalid email or username.'}
                            );
                        } else {
                            Notification.error(
                                {message: 'Failed to reset your password.' + ' (' + resp.statusText + ')'}
                            );
                        }
                    });
                };
            }
        ]
    });