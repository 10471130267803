/*global document, angular, console, $, JSEvents*/
'use strict';
/**
 * @typedef {Object} EventHandlers
 * @property {string} eventTypeString
 * @property {function} eventListenerFunc
 * @property {boolean} useCapture
 */

/**
 * @typedef {Object} JSEvents
 * @property {EventHandlers} eventHandlers
 */

var permissions = require('../../groupPermissions');
var adminTool = angular.module('AdminToolApp', [
    'pascalprecht.translate', // multi language support
    'ngCookies',
    'ui.bootstrap',
    'ui.router',
    'ui-notification',
    // angular bitcraft
    'bitcraft-rest-http',
    'bitcraft-faye',
    'bitcraft-db-edition',
    'bitcraft-db-edition-text',
    'bitcraft-db-edition-date',
    //Add components here
    'core',
    'login-screen',
    'shared-video-list',
    'create-video',
    'ai-create-video',
    'wait-video',
    'delete-user',
    'unity-webgl',
    'go-to-last-created',
    'delete-user-button'
]);

// Components
require('./core');
require('./login-screen');
require('./shared-video-list');
require('./create-video');
require('./ai-create-video');
require('./wait-video');
require('./delete-user');
require('./unity-webgl');
require('./db-edition');

function authenticate($q, $state, $timeout, $rootScope) {
    var targetNameDirty = $state.router.globals.transition._targetState._identifier;
    var targetName = targetNameDirty.name || targetNameDirty;
    if (targetName[0] === '.') {
        targetName = $state.router.globals.current.name + targetName;
    }
    //test if authenticated
    if ($rootScope.isPermissionValid(targetName) === false) {
        // The next bit of code is asynchronously tricky.
        $timeout(function () {
            // This code runs after the authentication promise has been rejected.
            // Go to the log-in page
            $state.go('root.login-screen');
        });

        // Reject the authentication promise to prevent the state from loading
        return $q.reject("Unauthorized");
    }

    return $q.when(); // Resolve the promise successfully
}


adminTool.config(['$stateProvider', '$urlRouterProvider', '$translateProvider', '$httpProvider',
    function ($stateProvider, $urlRouterProvider, $translateProvider, $httpProvider) {
        $translateProvider.useStaticFilesLoader({
            files: [{
                prefix: 'js/locale-',
                suffix: '.json'
            }, {
                prefix: 'bower_components/angular-bitcraft-color-picker/translations/locale-',
                suffix: '.json'
            }]
        });
        $translateProvider
            .preferredLanguage('ja')
            .fallbackLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escape');
        $translateProvider.useCookieStorage();

        $urlRouterProvider.when('/', '/login-screen');
        $urlRouterProvider.otherwise('/');

        $stateProvider
            .state('root', {
                url: '/',
                views: {
                    header: {
                        templateUrl: 'partials/header.html'
                    },
                    sidebar: {
                        templateUrl: 'partials/sidebar.html'
                    }
                }
            })

            // root actions
            .state('root.delete-user', {
                url: 'delete-user',
                params: {
                    username: null
                },
                views: {
                    'content@': {
                        component: 'deleteUser'
                    }
                },
                resolve: {authenticate: authenticate}
            })

            // login
            .state('root.login-screen', {
                url: 'login-screen',
                views: {
                    'content@': {
                        component: 'loginScreen'
                    }
                }
            })
            .state('root.login-screen.forgotten', {
                url: '/forgotten',
                views: {
                    'content@': {
                        component: 'forgottenPassword'
                    }
                }
            })
            .state('root.login-screen.register', {
                url: '/:token',
                views: {
                    'content@': {
                        component: 'register'
                    }
                }
            })

            // list of videos
            .state('root.list', {
                url: 'list'
            })
            .state('root.list.templates', {
                url: '/templates',
                views: {
                    'content@': {
                        component: 'sharedVideoList'
                    }
                },
                resolve: {authenticate: authenticate}
            })
            .state('root.list.recent', {
                url: '/recent',
                views: {
                    'content@': {
                        component: 'sharedVideoList'
                    }
                },
                resolve: {authenticate: authenticate}
            })
            .state('root.list.videos', {
                url: '/videos/:username',
                views: {
                    'content@': {
                        component: 'sharedVideoList'
                    }
                },
                resolve: {authenticate: authenticate}
            })

            // video creation
            .state('root.create', {
                url: 'create/:template',
                params: {
                    oldTemplate: null,
                    description: null
                },
                views: {
                    'content@': {
                        component: 'createVideo'
                    }
                },
                resolve: {authenticate: authenticate}
            })
            .state('root.wait-video', {
                url: 'wait-video/:filename',
                params: {
                    id: null,
                    ext: null,
                    lastFrameNumber: 0
                },
                views: {
                    'content@': {
                        component: 'waitVideo'
                    }
                },
                resolve: {authenticate: authenticate}
            })

            // AI video creation
            .state('root.ai-create-video', {
                url: 'ai-create-video',
                views: {
                    'content@': {
                        component: 'aiCreateVideo'
                    }
                },
                //resolve: {authenticate: authenticate}
            })

            // db-edition
            .state('root.db-edition', {
                url: 'db-edition/:command',
                views: {
                    'content@': {
                        component: 'bitcraftDbEditionGlobal'
                    }
                },
                resolve: {authenticate: authenticate}
            })
            .state('root.db-edition.add', {
                url: '/add',
                views: {
                    'content@': {
                        component: 'bitcraftDbEditionAdd'
                    }
                },
                resolve: {authenticate: authenticate}
            })
            .state('root.db-edition.id', {
                url: '/view/:id',
                views: {
                    'content@': {
                        component: 'bitcraftDbEditionId'
                    }
                },
                resolve: {authenticate: authenticate}
            });


        $httpProvider.interceptors.push('authInterceptor');
    }]
);

adminTool.service('authInterceptor', function ($q, $rootScope, $injector) {
    var service = this;

    service.responseError = function (response) {
        // expireToken is not an anonymous endpoint and will return a 401 code when not logged in
        if (response.status === 401 && response.config.url.indexOf('/expireToken') === -1) {
            $rootScope.logOut();
            var Notification = $injector.get('Notification');
            Notification.error(
                {message: 'You have been disconnected, please log in again.'}
            );
        }
        return $q.reject(response);
    };
});

adminTool.controller('AdminToolAppCtrl', [
    '$scope', 'UnityWebGL',
    function ($scope, UnityWebGL) {
        var navbarContext = {
            toggled: false,
            toggleButton: null
        };

        window.unity = UnityWebGL;
        UnityWebGL.onUnityReady(function () {
            var key;
            var event;
            for (key in JSEvents.eventHandlers) {
                if (JSEvents.eventHandlers.hasOwnProperty(key)) {
                    event = JSEvents.eventHandlers[key];
                    if (event.eventTypeString === 'keydown' ||
                            event.eventTypeString === 'keypress' ||
                            event.eventTypeString === 'keyup' ||
                            event.eventTypeString === 'mouseup' ||
                            event.eventTypeString === 'mousedown' ||
                            event.eventTypeString === 'mousemove') {
                        window.removeEventListener(event.eventTypeString, event.eventListenerFunc, event.useCapture);
                    }
                }
            }
        });

        $scope.applicationName = 'Video System';

        $scope.menu = [{
            title: 'MENU.LOGIN_SCREEN',
            href: 'root.login-screen',
            icon: 'fa-sign-in'
        }, {
            title: 'MENU.USER_LIST',
            href: 'root.db-edition({command: "list_users"})',
            icon: 'fa-database'
        }, {
            title: 'MENU.AI_CREATE_VIDEO',
            href: 'root.ai-create-video',
            icon: 'fa-laptop'
        }, {
            title: 'MENU.CREATE_VIDEO',
            href: 'root.list.templates',
            icon: 'fa-video-camera'
        }, {
            title: 'MENU.CREATED_RECENTLY',
            href: 'root.list.recent',
            icon: 'fa-history'
        }];

        /**
         * @param {Object} [$event]
         */
        $scope.toggleNav = function ($event) {
            if (!navbarContext.toggleButton) {
                navbarContext.toggleButton = $(angular.element($event.currentTarget));
            }
            navbarContext.toggleButton.toggleClass('visible-xs text-center');
            //noinspection JSUnresolvedFunction
            navbarContext.toggleButton.find('i').toggleClass('glyphicon-chevron-right glyphicon-chevron-left');
            // toggle the menu
            $('#lg-menu').toggleClass('hidden-xs').toggleClass('visible-xs');
            //noinspection JSJQueryEfficiency
            $('#lg-menu ul').toggleClass('hidden-xs').toggleClass('visible-xs');
            $('#xs-menu').toggleClass('visible-xs').toggleClass('hidden-xs');

            // we want to turn the sidebar as an overlay
            $('#sidebar').toggleClass('col-xs-1').toggleClass('col-xs-12');
            $('#content').toggleClass('hidden');

            navbarContext.toggled = !navbarContext.toggled;
        };

        $scope.onNavLinkClicked = function () {
            if (navbarContext.toggled) {
                $scope.toggleNav(null);
            }
        };
    }
]);

/** */
function expireCookie() {
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'username=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'groups=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/** */
function initLogin($rootScope, $state, $cookies, RestHttp) {
    var username = $cookies.get('username');
    var token = $cookies.get('token');
    var groups = $cookies.get('groups');
    if (!token || !groups) {
        $rootScope.username = 'guest';
        $rootScope.groups = ['guest'];
    } else {
        $rootScope.token = token;
        $rootScope.username = username;
        $rootScope.groups = groups.split(',');
    }

    $rootScope.logOut = function () {
        $rootScope.username = 'guest';
        $rootScope.groups = ['guest'];
        RestHttp.restPost('expireToken', {}).then(expireCookie, expireCookie);
        $state.go('root');
    };

    $rootScope.isPermissionValid = function (resource) {
        var permissionItemMatch = function (permission, resource) {
            var permLast = permission.length - 1;
            if (permission[permLast] === '*') {
                return permission.slice(0, -1) === resource.substring(0, permLast);
            }

            return permission === resource;
        };

        var i, len;
        var j, len2;
        var list;

        if (resource === '') {
            return true;
        }
        if (!$rootScope.groups) {
            return false;
        }

        var indexOfParenthesis = resource.indexOf('(');
        if (indexOfParenthesis > -1) {
            resource = resource.substr(0, indexOfParenthesis);
        }

        for (i = 0, len = $rootScope.groups.length; i < len; i += 1) {
            list = permissions[$rootScope.groups[i]];

            if (list) {
                for (j = 0, len2 = list.length; j < len2; j += 1) {
                    if (permissionItemMatch(list[j], resource)) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
}

adminTool.run([
    '$rootScope', '$state', '$translate', '$cookies', 'DBEdition', 'RestHttp',
    function ($rootScope, $state, $translate, $cookies, DBEdition, RestHttp) {
        console.log('Admin tool started');

        initLogin($rootScope, $state, $cookies, RestHttp);

        $rootScope.unityLoaded = false;
        $rootScope.changeLanguage = function (lang) {
            $translate.use(lang);
        };

        var dbEditionConfig = require('./db-edition/db-edition.conf.js');
        DBEdition.parseConfiguration(dbEditionConfig);
    }
]);

/**
 * @typedef {Object} applicationEnvironment
 * @property {string} name
 * @property {string} title
 */
