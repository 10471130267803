/**
 * Created by richard on 27/09/16.
 */

module.exports = {
    'admin': ['*'],
    'guest': ['root.login-screen'],
    'users': [
        // Angular routes
        'root.login-screen',

        'root.list.templates',
        'root.list.recent',

        'root.create',
        'root.wait-video',
        'root.ai-create-video',

        // Read permissions
        'readGlobalTopTags',
        'readOwnTopTags',

        // Operation permissions
        'createVideoConfiguration',
        'readVideoConfiguration',
        'retrieveAsset',
        'deleteVideoConfiguration'
    ],
    'videoRenderer': [
        'readVideoConfiguration',
        'retrieveAsset'
    ]
};
