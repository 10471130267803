/*global angular, $, JSEvents*/
'use strict';

module.exports.deleteUser = {
    templateUrl: './js/delete-user/delete-user.template.html',
    controller: [
        '$rootScope', '$scope', '$state', '$stateParams', 'RestHttp', '$translate', 'Notification',
        function ($rootScope, $scope, $state, $stateParams, RestHttp, $translate, Notification) {
            var username = $stateParams.username;

            var report = document.getElementById('report');
            var btnRetry = document.getElementById('btnRetry');

            function toMessage(lines) {
                var result = '';

                for (var i = 0; i < lines.length; i += 1) {
                    result += lines[i] + '<br/>';
                }

                return result;
            }

            function showRetryButton() {
                btnRetry.classList.remove('hidden');
            }

            function hideRetryButton() {
                btnRetry.classList.add('hidden');
            }

            function userDeletionInProgress() {
                return $translate.instant('USER_DELETION.REQUESTING_DELETION', { username: username })
            }

            function userDeletedSuccessfully() {
                return $translate.instant('USER_DELETION.USER_DELETED_SUCCESSFULLY', { username: username });
            }

            function failedToDeleteUser() {
                return $translate.instant('USER_DELETION.FAILED_TO_DELETE_USER', { username: username });
            }

            function userDeletionSucceeded(data) {
                return toMessage([
                    userDeletedSuccessfully(),
                    '',
                    $translate.instant('GENERAL.LOG') + ':',
                    toMessage(data.userLog)
                ]);
            }

            function userDeletionFailed(err) {
                return toMessage([
                    failedToDeleteUser(),
                    '',
                    $translate.instant('GENERAL.STATUS') + ': ' + err.status,
                    '',
                    $translate.instant('GENERAL.LOG') + ':',
                    toMessage(err.data.userLog),
                    // '',
                    $translate.instant('GENERAL.MESSAGE') + ':',
                    err.data.error
                ]);
            }

            function startUserDeletion() {
                report.innerHTML = userDeletionInProgress();

                RestHttp
                    .restPost('deleteUser', { username: username })
                    .then(
                        function (data) {
                            report.innerHTML = userDeletionSucceeded(data);
                            Notification.success({ message: userDeletedSuccessfully() });
                        },
                        function (err) {
                            report.innerHTML = userDeletionFailed(err);
                            showRetryButton();
                            Notification.error({ message: failedToDeleteUser() });
                        }
                    );
            };

            this.$onInit = function () {
                if (!username) {
                    $state.go('root.db-edition', {command: 'list_users'});
                    return;
                }

                btnRetry.addEventListener('click', function () {
                    hideRetryButton();
                    startUserDeletion();
                });

                startUserDeletion();
            };

            this.$onDestroy = function () {
            };
        }
    ]
};
