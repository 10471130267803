'use strict';

var LOCALSTORAGE_KEY = 'infoMessages';

/** */
var InfoMessageController = function () {
    var self = this;

    if (!localStorage.getItem(LOCALSTORAGE_KEY)) {
        localStorage.setItem(LOCALSTORAGE_KEY, '{}');
    }

    var _messageKey;
    Object.defineProperty(this, 'messageKey', {
        get: function () {
            return _messageKey;
        },
        set: function (value) {
            _messageKey = value;
            self.hide = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY))[_messageKey];
        }
    });

};

module.exports = {
    templateUrl: './js/create-video/info-message.template.html',
    bindings: {
        'messageKey': '@'
    },

    controller: [ InfoMessageController ]
};

InfoMessageController.prototype.hideMessage = function () {
    this.hide = true;

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(
        Object.assign(
            JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)),
            {
                [this.messageKey]: true
            }
        )
    ));
}
