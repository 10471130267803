/*global angular, $, JSEvents*/
'use strict';

module.exports.waitVideo = {
    templateUrl: './js/wait-video/wait-video.template.html',
    controller: [
        '$rootScope', '$scope', '$state', '$stateParams', 'RestHttp', 'Faye',
        function ($rootScope, $scope, $state, $stateParams, RestHttp, Faye) {
            var self = this;

            var checkForVideo = function () {
                var xmlHttp = new XMLHttpRequest();

                xmlHttp.onloadend = function (event) {
                    if (event.target.status === 200) {
                        self.done = true;
                        $scope.$applyAsync();
                    } else {
                        console.log('Received code ' + event.target.status + '. Retrying...');
                        self.timer = setTimeout(checkForVideo, 300);
                    }
                };

                xmlHttp.open('GET', 'public/recent/' + $rootScope.username + '/' + self.filename + self.ext, true);
                xmlHttp.send(null);
            };

            /**
             * @param {number} value
             */
            function setProgress(value) {
                if (self.progress === 100) {
                    return;
                }

                if (value > 100) {
                    value = 100;
                }

                if (value > self.progress) {
                    self.progress = value;
                    $('#progressbar').css('width', self.progress + '%');
                    $scope.$applyAsync();
                }
            }

            /**
             * @param {object} ignore
             * @param {number|string} message
             */
            function onFayeUpdate(ignore, message) {
                if (message === 'ERROR') {
                    // TODO do something
                    console.error('Failed');
                } else if (message === 'DONE') {
                    setProgress(100);
                    self.timer = setTimeout(checkForVideo, 300);
                } else if (message === 0 && self.progress < 25) {
                    setProgress(self.progress + 3);
                } else {
                    setProgress(27 + Math.floor(73 * parseInt(message, 10) / 100));
                }
            }

            this.$onInit = function () {
                if (!$stateParams.ext) {
                    $state.go('root.list.templates');
                    return;
                }

                self.id = $stateParams.id;
                self.filename = $stateParams.filename;
                self.ext = $stateParams.ext;
                self.done = false;
                self.username = $rootScope.username;

                self.progress = 0;
                self.unsubscribe = Faye.subscribe('/video-' + self.id, onFayeUpdate, false);
            };

            this.$onDestroy = function () {
                self.unsubscribe.unsubscribe();
                if (self.timer) {
                    clearTimeout(self.timer);
                }
            };

            /**
             * @param {string} videoId
             */
            self.edit = function (videoId) {
                RestHttp.restGet('getVideoConfiguration', {username: $rootScope.username, videoId: videoId}).then(
                    /**
                     * @param {{data: string}} data
                     */
                    function (data) {
                        var template = data;
                        $state.go('root.create', {
                            template: template.template,
                            description: template.description,
                            oldTemplate: template
                        });
                    },
                    function (err) {
                        console.error(err);
                    }
                );
            };
        }
    ]
};
